import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Monitor, Shield, Cloud, Network, PenTool, Camera, Cable, 
  Wrench, Code, Lock, DoorOpen
} from 'lucide-react';
import { GoogleReviews } from '../components/GoogleReviews';

export function HomePage() {
  const services = [
    {
      icon: Monitor,
      title: "IT Support",
      description: "Comprehensive IT support solutions with 24/7 monitoring.",
      href: "/services/it-support"
    },
    {
      icon: Shield,
      title: "Cybersecurity",
      description: "Enterprise-grade security solutions to protect your business.",
      href: "/services/cybersecurity"
    },
    {
      icon: Cloud,
      title: "Microsoft 365",
      description: "Cloud-based productivity tools and collaboration solutions.",
      href: "/services/microsoft-365"
    },
    {
      icon: Network,
      title: "Network Solutions",
      description: "Professional network infrastructure and phone systems.",
      href: "/services/network"
    },
    {
      icon: PenTool,
      title: "IT Projects",
      description: "Expert project management and implementation services.",
      href: "/services/projects"
    },
    {
      icon: Lock,
      title: "Security & Access",
      description: "Advanced surveillance solutions and access control systems.",
      href: "/services/security-cameras"
    },
    {
      icon: Cable,
      title: "Cabling Solutions",
      description: "Professional infrastructure and structured cabling services.",
      href: "/services/cabling"
    },
    {
      icon: Wrench,
      title: "Computer Repair",
      description: "Expert on-site computer repair and maintenance services.",
      href: "/services/computer-repair"
    },
    {
      icon: Code,
      title: "Web Services",
      description: "Custom web development, SEO, and AI-powered solutions.",
      href: "/services/web-development"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-black">
      <main className="pt-20">
        {/* Hero Section */}
        <section className="relative h-[600px] flex items-center justify-center overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-black">
            <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1519389950473-47ba0277781c?auto=format&fit=crop&q=80&w=2000')] mix-blend-overlay opacity-40" />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent" />
          </div>
          <div className="relative container mx-auto px-4 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
              className="space-y-6"
            >
              <h1 className="text-6xl md:text-7xl font-bold mb-6 text-white tracking-tight">
                Transform Your Business with XXL IT Services
              </h1>
              <p className="text-xl md:text-2xl text-gray-200 mb-8 max-w-3xl mx-auto font-light">
                We deliver cutting-edge solutions that drive growth and innovation
              </p>
              <Link 
                to="/contact"
                className="inline-block bg-white/90 backdrop-blur-sm text-black px-8 py-4 rounded-full text-lg font-medium hover:bg-white transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                Get Started
              </Link>
            </motion.div>
          </div>
        </section>

        {/* Services Grid */}
        <section className="py-24 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
          <div className="container mx-auto px-4">
            <motion.h2 
              className="text-4xl md:text-5xl font-bold text-center mb-16 tracking-tight text-gray-900 dark:text-white"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              Our Services
            </motion.h2>
            <div className="grid md:grid-cols-3 gap-8">
              {services.map((service, index) => {
                const Icon = service.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="group relative"
                  >
                    <Link 
                      to={service.href}
                      className="block relative bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 rounded-[2.5rem] p-8 transition-all duration-500 hover:scale-[1.02] hover:-translate-y-1 overflow-hidden backdrop-blur-xl border border-white/20 dark:border-gray-700/20"
                    >
                      {/* Glass effect overlay */}
                      <div className="absolute inset-0 bg-white/40 dark:bg-gray-800/40 backdrop-blur-xl rounded-[2.5rem] opacity-80" />
                      
                      {/* Hover gradient */}
                      <div className="absolute inset-0 bg-gradient-to-br from-primary-100/20 via-primary-50/10 to-transparent dark:from-primary-500/10 dark:via-primary-400/5 dark:to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-[2.5rem]" />
                      
                      {/* Content */}
                      <div className="relative z-10">
                        <div className="bg-gradient-to-br from-gray-100 to-white dark:from-gray-700 dark:to-gray-800 w-16 h-16 rounded-2xl flex items-center justify-center mb-6 shadow-lg transition-transform duration-500 group-hover:scale-110 group-hover:shadow-xl">
                          <Icon className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                        </div>
                        <h3 className="text-xl font-semibold mb-3 text-gray-900 dark:text-white group-hover:text-primary-600 dark:group-hover:text-primary-400 transition-colors">
                          {service.title}
                        </h3>
                        <p className="text-gray-600 dark:text-gray-300">
                          {service.description}
                        </p>
                      </div>

                      {/* Bottom highlight */}
                      <div className="absolute bottom-0 left-[10%] right-[10%] h-[1px] bg-gradient-to-r from-transparent via-primary-200 dark:via-primary-700 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                    </Link>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Google Reviews Section */}
        <GoogleReviews />
      </main>
    </div>
  );
}