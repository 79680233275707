import React from 'react';
import { Shield, Lock, AlertTriangle, FileCheck } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function CybersecurityPage() {
  const services = [
    {
      icon: Lock,
      title: "Security Audits",
      description: "Comprehensive assessment of your security infrastructure."
    },
    {
      icon: AlertTriangle,
      title: "Threat Detection",
      description: "24/7 monitoring and real-time threat detection."
    },
    {
      icon: FileCheck,
      title: "Compliance",
      description: "Ensure compliance with industry security standards."
    }
  ];

  return (
    <>
      <SEO 
        title="Enterprise Cybersecurity Solutions in Meridian | XXL IT Services" 
        description="Protect your business with enterprise-grade cybersecurity solutions. Expert security audits, threat detection, and compliance services in Meridian, ID."
        path="/services/cybersecurity"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-16">
              <Shield className="w-16 h-16 mx-auto mb-6 text-primary-600 dark:text-primary-400" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 dark:text-white">Cybersecurity Services</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Protect your business with enterprise-grade security solutions
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {services.map((service, index) => {
                const Icon = service.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-lg dark:shadow-gray-900/30"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600 dark:text-primary-400" />
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{service.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{service.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none dark:prose-invert">
              <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Comprehensive Security Solutions</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                In today's digital landscape, cybersecurity is not optional. Our comprehensive security 
                solutions protect your business from evolving cyber threats while ensuring business continuity.
              </p>

              <div className="bg-primary-50 dark:bg-primary-900/20 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Our Security Approach</h3>
                <ul className="space-y-4">
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Proactive threat monitoring and prevention</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Regular security assessments and penetration testing</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Employee security awareness training</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Incident response and recovery planning</span>
                  </li>
                </ul>
              </div>

              <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Security Compliance</h3>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                We ensure your business meets industry security standards and compliance requirements:
              </p>
              <ul className="space-y-4 mb-8">
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">•</span>
                  <span>GDPR compliance</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">•</span>
                  <span>HIPAA security standards</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">•</span>
                  <span>PCI DSS requirements</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">•</span>
                  <span>ISO 27001 certification support</span>
                </li>
              </ul>

              {/* Security Features Grid */}
              <div className="grid md:grid-cols-2 gap-6 mb-8">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg dark:shadow-gray-900/30">
                  <h4 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white">
                    Network Security
                  </h4>
                  <ul className="space-y-2">
                    <li className="text-gray-600 dark:text-gray-300">• Firewall management</li>
                    <li className="text-gray-600 dark:text-gray-300">• Intrusion detection</li>
                    <li className="text-gray-600 dark:text-gray-300">• VPN solutions</li>
                  </ul>
                </div>
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg dark:shadow-gray-900/30">
                  <h4 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white">
                    Data Protection
                  </h4>
                  <ul className="space-y-2">
                    <li className="text-gray-600 dark:text-gray-300">• Encryption services</li>
                    <li className="text-gray-600 dark:text-gray-300">• Backup solutions</li>
                    <li className="text-gray-600 dark:text-gray-300">• Access control</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-12 text-center">
              <a 
                href="/contact" 
                className="inline-block bg-primary-600 hover:bg-primary-700 text-white px-8 py-4 rounded-lg text-lg font-medium transition-colors"
              >
                Schedule a Security Assessment
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}