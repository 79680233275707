import React from 'react';
import { Star, Quote, User } from 'lucide-react';
import { motion } from 'framer-motion';

const reviews = [
  {
    id: 1,
    author: "Michael Anderson",
    rating: 5,
    text: "Excellent IT support! They resolved our network issues quickly and professionally. Highly recommend their services.",
    date: "2024-02-15"
  },
  {
    id: 2,
    author: "Sarah Johnson",
    rating: 5,
    text: "XXL IT Services has been managing our company's IT infrastructure for over a year now. Their response time is incredible and their team is very knowledgeable.",
    date: "2024-02-10"
  },
  {
    id: 3,
    author: "Mike Brown",
    rating: 5,
    text: "Great experience with their cybersecurity services. They helped us implement robust security measures and provided excellent staff training.",
    date: "2024-02-05"
  }
];

export function GoogleReviews() {
  return (
    <section className="py-16 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900 dark:text-white">What Our Clients Say</h2>
          <div className="flex items-center justify-center mb-4">
            {[...Array(5)].map((_, i) => (
              <Star key={i} className="w-6 h-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            5.0 rating based on 23 Google Reviews
          </p>
          <a 
            href="https://g.co/kgs/w7e81en"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center mt-2 text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300"
          >
            View all reviews on Google
          </a>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {reviews.map((review, index) => (
            <motion.div
              key={review.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg relative"
            >
              <Quote className="absolute top-4 right-4 w-8 h-8 text-gray-200 dark:text-gray-700" />
              <div className="flex items-center mb-4">
                {[...Array(review.rating)].map((_, i) => (
                  <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                ))}
              </div>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                "{review.text}"
              </p>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-full bg-primary-100 dark:bg-primary-900 flex items-center justify-center mr-3">
                    <User className="w-6 h-6 text-primary-600 dark:text-primary-400" />
                  </div>
                  <div>
                    <p className="font-semibold text-gray-900 dark:text-white">
                      {review.author}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {new Date(review.date).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <img 
                    src="https://www.gstatic.com/images/branding/googlelogo/svg/googlelogo_clr_74x24px.svg"
                    alt="Google"
                    className="h-6 w-auto"
                    loading="lazy"
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="text-center mt-12">
          <a
            href="https://g.co/kgs/w7e81en"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 transition-colors"
          >
            Write a Review
          </a>
        </div>
      </div>
    </section>
  );
}