import React from 'react';
import { SEO } from '../components/layout/SEO';
import { motion } from 'framer-motion';
import { Shield, Lock, Eye, FileCheck, Bell, Users, Database, Globe, Mail, Phone } from 'lucide-react';

export function PrivacyPage() {
  const sections = [
    {
      icon: Shield,
      title: "Information Collection",
      content: [
        {
          subtitle: "Personal Information",
          items: [
            "Name and contact details",
            "Business information",
            "Technical data",
            "Usage statistics"
          ]
        },
        {
          subtitle: "Collection Methods",
          items: [
            "Direct interactions",
            "Automated technologies",
            "Third-party sources"
          ]
        }
      ]
    },
    {
      icon: Lock,
      title: "Data Security",
      content: [
        {
          subtitle: "Protection Measures",
          items: [
            "Enterprise-grade encryption",
            "Secure data centers",
            "Regular security audits",
            "Access control systems"
          ]
        }
      ]
    },
    {
      icon: Eye,
      title: "Information Usage",
      content: [
        {
          subtitle: "How We Use Your Data",
          items: [
            "Service delivery and improvement",
            "Communication and support",
            "Transaction processing",
            "Security maintenance"
          ]
        }
      ]
    },
    {
      icon: Bell,
      title: "Your Privacy Rights",
      content: [
        {
          subtitle: "Your Controls",
          items: [
            "Access your data",
            "Request corrections",
            "Delete your information",
            "Opt-out options"
          ]
        }
      ]
    }
  ];

  return (
    <>
      <SEO 
        title="Privacy Policy | XXL IT Services" 
        description="Learn about how XXL IT Services collects, uses, and protects your personal information. Our privacy policy explains your rights and our data practices."
        path="/privacy"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            {/* Header */}
            <div className="text-center mb-16">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.2 }}
                className="inline-block p-4 rounded-full bg-primary-50 dark:bg-primary-900/20 mb-6"
              >
                <Shield className="w-12 h-12 text-primary-600 dark:text-primary-400" />
              </motion.div>
              <h1 className="text-4xl md:text-5xl font-bold mb-4">Privacy Policy</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                How we protect and respect your privacy
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                Last updated: {new Date().toLocaleDateString()}
              </p>
            </div>

            {/* Introduction */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-lg mb-12"
            >
              <h2 className="text-2xl font-semibold mb-4">Our Commitment to Privacy</h2>
              <p className="text-gray-600 dark:text-gray-300">
                At XXL IT Services, we take your privacy seriously. This policy outlines our practices for collecting, 
                using, and protecting your personal information. We believe in transparency and giving you control 
                over your data.
              </p>
            </motion.div>

            {/* Main Sections */}
            <div className="space-y-8">
              {sections.map((section, index) => {
                const Icon = section.icon;
                return (
                  <motion.section
                    key={section.title}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 + index * 0.1 }}
                    className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-lg"
                  >
                    <div className="flex items-center mb-6">
                      <div className="p-3 rounded-full bg-primary-50 dark:bg-primary-900/20 mr-4">
                        <Icon className="w-6 h-6 text-primary-600 dark:text-primary-400" />
                      </div>
                      <h2 className="text-2xl font-semibold">{section.title}</h2>
                    </div>

                    <div className="space-y-6">
                      {section.content.map((subsection) => (
                        <div key={subsection.subtitle}>
                          <h3 className="text-lg font-medium mb-3 text-gray-900 dark:text-white">
                            {subsection.subtitle}
                          </h3>
                          <ul className="space-y-2">
                            {subsection.items.map((item, i) => (
                              <li 
                                key={i}
                                className="flex items-center text-gray-600 dark:text-gray-300"
                              >
                                <span className="w-1.5 h-1.5 rounded-full bg-primary-600 dark:bg-primary-400 mr-3" />
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </motion.section>
                );
              })}
            </div>

            {/* Contact Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="mt-12 bg-gradient-to-br from-primary-50 to-primary-100 dark:from-primary-900/20 dark:to-primary-800/20 rounded-2xl p-8 shadow-lg"
            >
              <h2 className="text-2xl font-semibold mb-6">Contact Us About Privacy</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                If you have any questions about our privacy practices or would like to exercise your privacy rights, 
                please don't hesitate to contact us.
              </p>
              <div className="grid md:grid-cols-2 gap-6">
                <a 
                  href="mailto:privacy@xxlservices.com"
                  className="flex items-center p-4 bg-white dark:bg-gray-800 rounded-xl hover:shadow-md transition-shadow"
                >
                  <Mail className="w-6 h-6 text-primary-600 dark:text-primary-400 mr-3" />
                  <div>
                    <p className="font-medium">Email Us</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">privacy@xxlservices.com</p>
                  </div>
                </a>
                <a 
                  href="tel:+12083284500"
                  className="flex items-center p-4 bg-white dark:bg-gray-800 rounded-xl hover:shadow-md transition-shadow"
                >
                  <Phone className="w-6 h-6 text-primary-600 dark:text-primary-400 mr-3" />
                  <div>
                    <p className="font-medium">Call Us</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">(208) 328-4500</p>
                  </div>
                </a>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
}