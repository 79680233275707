import React from 'react';
import { Cable, Network, Wifi, Settings } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function CablingPage() {
  const services = [
    {
      icon: Network,
      title: "Structured Cabling",
      description: "Professional cable installation and management."
    },
    {
      icon: Wifi,
      title: "Network Points",
      description: "Strategic placement of network access points."
    },
    {
      icon: Settings,
      title: "Infrastructure",
      description: "Complete infrastructure setup and testing."
    }
  ];

  return (
    <>
      <SEO 
        title="Professional Cabling Solutions in Meridian | XXL IT Services" 
        description="Expert structured cabling and infrastructure services. Professional cable installation and network points for businesses in Meridian, ID."
        path="/services/cabling"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-16">
              <Cable className="w-16 h-16 mx-auto mb-6 text-primary-600" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Cabling Solutions</h1>
              <p className="text-xl text-gray-600">
                Professional infrastructure and cabling services
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {services.map((service, index) => {
                const Icon = service.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white p-6 rounded-2xl shadow-lg"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600" />
                    <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                    <p className="text-gray-600">{service.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none">
              <h2 className="text-3xl font-bold mb-6">Professional Cabling Services</h2>
              <p className="text-gray-600 mb-6">
                Our cabling solutions provide the foundation for reliable network infrastructure. 
                We ensure proper installation and management of all your cabling needs.
              </p>

              <div className="bg-primary-50 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4">Our Cabling Services Include:</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Cat5e/Cat6/Cat6a cable installation</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Fiber optic cabling</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Cable management systems</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Network point installation</span>
                  </li>
                </ul>
              </div>

              <h3 className="text-2xl font-bold mb-4">Installation Process</h3>
              <ol className="space-y-4 mb-8">
                <li className="flex items-start">
                  <span className="mr-2">1.</span>
                  <span>Site survey and requirements analysis</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">2.</span>
                  <span>Infrastructure planning</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">3.</span>
                  <span>Professional installation</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">4.</span>
                  <span>Testing and certification</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">5.</span>
                  <span>Documentation and warranty</span>
                </li>
              </ol>
            </div>

            <div className="mt-12 text-center">
              <a 
                href="/contact" 
                className="inline-block bg-primary-600 text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-primary-700 transition-colors"
              >
                Request a Quote
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}