import React from 'react';
import { Link } from 'react-router-dom';
import { 
  MapPin, Phone, Mail, Clock, Facebook, 
  Linkedin, Shield, Monitor, Cloud,
  Network, PenTool, Camera, Cable, Wrench, Code
} from 'lucide-react';

// Get version from Vite's define feature
declare const version: string;

export function Footer() {
  const services = [
    { name: 'IT Support', href: '/services/it-support', icon: Monitor },
    { name: 'Cybersecurity', href: '/services/cybersecurity', icon: Shield },
    { name: 'Microsoft 365', href: '/services/microsoft-365', icon: Cloud },
    { name: 'Network Solutions', href: '/services/network', icon: Network },
    { name: 'IT Projects', href: '/services/projects', icon: PenTool },
    { name: 'Security Cameras', href: '/services/security-cameras', icon: Camera },
    { name: 'Cabling Solutions', href: '/services/cabling', icon: Cable },
    { name: 'Computer Repair', href: '/services/computer-repair', icon: Wrench },
    { name: 'Web Services', href: '/services/web-development', icon: Code }
  ];

  const serviceAreas = [
    'Meridian',
    'Boise',
    'Nampa',
    'Eagle',
    'Star',
    'Kuna',
    'Garden City',
    'Caldwell'
  ];

  return (
    <footer className="bg-gray-900 text-white pt-16 pb-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Company Information */}
          <div>
            <h3 className="text-xl font-bold mb-4">XXL IT Services</h3>
            <div className="space-y-3">
              <div className="flex items-start">
                <MapPin className="w-5 h-5 mr-2 mt-1 flex-shrink-0" />
                <address className="not-italic">
                  1624 E Territory Dr<br />
                  Meridian, ID 83646<br />
                  United States
                </address>
              </div>
              <div className="flex items-center">
                <Phone className="w-5 h-5 mr-2 flex-shrink-0" />
                <a href="tel:+12083284500" className="hover:text-primary-400 transition-colors">
                  (208) 328-4500
                </a>
              </div>
              <div className="flex items-center">
                <Mail className="w-5 h-5 mr-2 flex-shrink-0" />
                <a href="mailto:info@xxlservices.com" className="hover:text-primary-400 transition-colors">
                  info@xxlservices.com
                </a>
              </div>
              <div className="flex items-start">
                <Clock className="w-5 h-5 mr-2 mt-1 flex-shrink-0" />
                <div>
                  <p>Monday - Friday: 9:00 AM - 5:00 PM</p>
                  <p>24/7 Emergency Support Available</p>
                </div>
              </div>
            </div>
          </div>

          {/* Services */}
          <div>
            <h3 className="text-xl font-bold mb-4">Our Services</h3>
            <ul className="space-y-2">
              {services.map((service) => (
                <li key={service.href}>
                  <Link 
                    to={service.href}
                    className="hover:text-primary-400 transition-colors flex items-center"
                  >
                    <service.icon className="w-4 h-4 mr-2" />
                    {service.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Service Areas */}
          <div>
            <h3 className="text-xl font-bold mb-4">Service Areas</h3>
            <ul className="grid grid-cols-2 gap-2">
              {serviceAreas.map((area) => (
                <li key={area}>
                  <Link 
                    to={`/services/${area.toLowerCase()}`}
                    className="hover:text-primary-400 transition-colors"
                  >
                    {area}, ID
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Social & Certifications */}
          <div>
            <h3 className="text-xl font-bold mb-4">Connect With Us</h3>
            <div className="flex space-x-4 mb-6">
              <a 
                href="https://facebook.com/XXLIT" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-primary-400 transition-colors"
                aria-label="Follow us on Facebook"
              >
                <Facebook className="w-6 h-6" />
              </a>
              <a 
                href="https://www.linkedin.com/company/95933739/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-primary-400 transition-colors"
                aria-label="Follow us on LinkedIn"
              >
                <Linkedin className="w-6 h-6" />
              </a>
            </div>
            <div className="space-y-2">
              <a 
                href="https://www.bbb.org/us/id/meridian/profile/computer-services/xxl-it-services-1296-1000013388" 
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-white rounded-lg p-2 hover:opacity-90 transition-opacity"
              >
                <Shield className="w-12 h-12 text-blue-600" />
                <span className="sr-only">BBB Accredited Business</span>
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="text-sm text-gray-400">
              <p>© {new Date().getFullYear()} XXL IT Services. All rights reserved.</p>
              <p className="mt-1">Version {version}</p>
            </div>
            <div className="flex space-x-6 text-sm text-gray-400">
              <Link to="/privacy" className="hover:text-white transition-colors">
                Privacy Policy
              </Link>
              <Link to="/terms" className="hover:text-white transition-colors">
                Terms of Service
              </Link>
              <a 
                href="/sitemap.xml" 
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-white transition-colors"
              >
                Sitemap
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Schema.org LocalBusiness Markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "@id": "https://xxlservices.com",
          "name": "XXL IT Services",
          "image": "https://xxlservices.com/logo.png",
          "description": "Professional IT services including support, cybersecurity, and infrastructure solutions for businesses in Meridian, Idaho.",
          "url": "https://xxlservices.com",
          "telephone": "+1-208-328-4500",
          "email": "info@xxlservices.com",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "1624 E Territory Dr",
            "addressLocality": "Meridian",
            "addressRegion": "ID",
            "postalCode": "83646",
            "addressCountry": "US"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 43.6087,
            "longitude": -116.3515
          },
          "areaServed": serviceAreas.map(area => ({
            "@type": "City",
            "name": area,
            "containedInPlace": {
              "@type": "State",
              "name": "Idaho"
            }
          })),
          "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday"
            ],
            "opens": "09:00",
            "closes": "17:00"
          },
          "sameAs": [
            "https://facebook.com/XXLIT",
            "https://www.linkedin.com/company/95933739/"
          ],
          "priceRange": "$$",
          "serviceArea": {
            "@type": "GeoCircle",
            "geoMidpoint": {
              "@type": "GeoCoordinates",
              "latitude": 43.6087,
              "longitude": -116.3515
            },
            "geoRadius": "50000"
          },
          "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "IT Services",
            "itemListElement": services.map(service => ({
              "@type": "Offer",
              "itemOffered": {
                "@type": "Service",
                "name": service.name,
                "url": `https://xxlservices.com${service.href}`
              }
            }))
          }
        })}
      </script>
    </footer>
  );
}