import React from 'react';
import { Code, Search, Bot, Check, ArrowRight, Globe, ShoppingCart, FileCode } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function WebServicesPage() {
  const features = [
    {
      icon: Globe,
      title: "Custom Website Development",
      description: "Professional, custom-designed websites built to your specifications"
    },
    {
      icon: ShoppingCart,
      title: "E-commerce Solutions",
      description: "Full-featured online stores with secure payment processing"
    },
    {
      icon: FileCode,
      title: "CMS Integration",
      description: "Easy-to-use content management systems for your website"
    }
  ];

  return (
    <>
      <SEO 
        title="Professional Web Development Services | XXL IT Services" 
        description="Custom web development and SEO solutions for businesses. Expert web services and AI-powered solutions in Meridian, ID."
        path="/services/web-development"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-16">
              <Code className="w-16 h-16 mx-auto mb-6 text-primary-600" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Web Development Services</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Transform your online presence with professional web solutions
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-lg"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600" />
                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none dark:prose-invert">
              <h2 className="text-3xl font-bold mb-6">Professional Web Development</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Our web development services provide comprehensive solutions for businesses looking to establish 
                or enhance their online presence. We focus on creating modern, responsive, and user-friendly 
                websites that drive results.
              </p>

              <div className="bg-primary-50 dark:bg-primary-900/20 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4">Our Web Services Include:</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                    <span>Custom website design and development</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                    <span>E-commerce platform development</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                    <span>Content management system integration</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                    <span>Responsive mobile-first design</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                    <span>Website maintenance and support</span>
                  </li>
                  <li className="flex items-start">
                    <Check className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                    <span>Performance optimization</span>
                  </li>
                </ul>
              </div>

              <h3 className="text-2xl font-bold mb-4">Development Process</h3>
              <ol className="space-y-4 mb-8">
                <li className="flex items-start">
                  <span className="mr-2">1.</span>
                  <span>Requirements gathering and analysis</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">2.</span>
                  <span>Design and prototyping</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">3.</span>
                  <span>Development and testing</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">4.</span>
                  <span>Content integration</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">5.</span>
                  <span>Launch and maintenance</span>
                </li>
              </ol>

              <div className="bg-gradient-to-br from-primary-50 to-primary-100 dark:from-primary-900/20 dark:to-primary-800/20 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4">Why Choose Our Web Services?</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <ArrowRight className="w-5 h-5 text-primary-600 mr-2 mt-1 flex-shrink-0" />
                    <span>Expert development team with years of experience</span>
                  </li>
                  <li className="flex items-start">
                    <ArrowRight className="w-5 h-5 text-primary-600 mr-2 mt-1 flex-shrink-0" />
                    <span>Modern, responsive designs that work on all devices</span>
                  </li>
                  <li className="flex items-start">
                    <ArrowRight className="w-5 h-5 text-primary-600 mr-2 mt-1 flex-shrink-0" />
                    <span>Focus on performance and user experience</span>
                  </li>
                  <li className="flex items-start">
                    <ArrowRight className="w-5 h-5 text-primary-600 mr-2 mt-1 flex-shrink-0" />
                    <span>Ongoing support and maintenance</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-12 text-center">
              <a 
                href="/contact" 
                className="inline-block bg-primary-600 text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-primary-700 transition-colors"
              >
                Discuss Your Project
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}