import React from 'react';
import { Menu, X, Monitor, Shield, Cloud, Network, PenTool, Camera, Cable, Wrench, Code, Lock } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ThemeToggle } from '../ThemeToggle';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  const services = [
    { name: 'IT Support', icon: Monitor, href: '/services/it-support' },
    { name: 'Cybersecurity', icon: Shield, href: '/services/cybersecurity' },
    { name: 'Microsoft 365', icon: Cloud, href: '/services/microsoft-365' },
    { name: 'Network Solutions', icon: Network, href: '/services/network' },
    { name: 'IT Projects', icon: PenTool, href: '/services/projects' },
    { name: 'Security & Access', icon: Lock, href: '/services/security-cameras' },
    { name: 'Cabling Solutions', icon: Cable, href: '/services/cabling' },
    { name: 'Computer Repair', icon: Wrench, href: '/services/computer-repair' },
    { name: 'Web Services', icon: Code, href: '/services/web-development' }
  ];

  return (
    <header className="fixed w-full bg-white/80 dark:bg-gray-900/80 backdrop-blur-md z-50 border-b border-gray-100 dark:border-gray-800 transition-colors duration-300">
      <nav className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <Link 
            to="/" 
            onClick={handleLinkClick}
            className="text-2xl font-bold text-gray-900 dark:text-white"
          >
            XXL IT Services
          </Link>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className="text-gray-600 dark:text-gray-300 hover:text-black dark:hover:text-white transition-colors">Home</Link>
            <div className="relative group">
              <button className="text-gray-600 dark:text-gray-300 hover:text-black dark:hover:text-white transition-colors">Services</button>
              <div className="absolute top-full left-0 w-64 hidden group-hover:block">
                <div className="bg-white dark:bg-gray-800 mt-2 rounded-lg shadow-lg dark:shadow-gray-900/50 p-4 grid gap-2">
                  {services.map((service) => {
                    const Icon = service.icon;
                    return (
                      <Link
                        key={service.href}
                        to={service.href}
                        className="flex items-center space-x-2 p-2 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md text-gray-600 dark:text-gray-300 transition-colors"
                      >
                        <Icon className="w-5 h-5" />
                        <span>{service.name}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <Link 
              to="/contact" 
              className="bg-black dark:bg-white text-white dark:text-black px-4 py-2 rounded-lg hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors"
            >
              Contact Us
            </Link>
            <ThemeToggle />
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center space-x-4">
            <ThemeToggle />
            <button 
              className="text-gray-600 dark:text-gray-300"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            >
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="md:hidden absolute top-full left-0 right-0 bg-white dark:bg-gray-900 border-b border-gray-100 dark:border-gray-800"
            >
              <div className="flex flex-col space-y-4 p-4">
                <Link to="/" onClick={handleLinkClick} className="text-gray-600 dark:text-gray-300 hover:text-black dark:hover:text-white transition-colors">Home</Link>
                <div className="space-y-2">
                  <p className="font-medium text-gray-900 dark:text-white">Services</p>
                  {services.map((service) => {
                    const Icon = service.icon;
                    return (
                      <Link
                        key={service.href}
                        to={service.href}
                        onClick={handleLinkClick}
                        className="flex items-center space-x-2 p-2 hover:bg-gray-50 dark:hover:bg-gray-800 rounded-md text-gray-600 dark:text-gray-300"
                      >
                        <Icon className="w-5 h-5" />
                        <span>{service.name}</span>
                      </Link>
                    );
                  })}
                </div>
                <Link 
                  to="/contact"
                  onClick={handleLinkClick}
                  className="bg-black dark:bg-white text-white dark:text-black px-4 py-2 rounded-lg hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors text-center"
                >
                  Contact Us
                </Link>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </header>
  );
}