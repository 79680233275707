// Google Analytics Event Types
export type GAEventParams = {
  category: string;
  action: string;
  label?: string;
  value?: number;
};

// Track page views
export function trackPageView(path: string) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'page_view', {
      page_path: path,
      page_title: document.title,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track form interactions
export function trackFormInteraction(formName: string, action: string, details?: Record<string, any>) {
  if (typeof gtag !== 'undefined') {
    gtag('event', action, {
      event_category: 'Form Interaction',
      event_label: formName,
      ...details,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track form submissions
export function trackFormSubmission(formName: string, success: boolean, details?: Record<string, any>) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'form_submission', {
      event_category: 'Forms',
      event_label: formName,
      success: success,
      ...details,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track form validation errors
export function trackFormError(formName: string, errorType: string, details?: Record<string, any>) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'form_error', {
      event_category: 'Form Errors',
      event_label: formName,
      error_type: errorType,
      ...details,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track form field completion time
export function trackFieldCompletionTime(formName: string, fieldName: string, timeInSeconds: number) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'field_completion_time', {
      event_category: 'Form Analytics',
      event_label: `${formName} - ${fieldName}`,
      value: timeInSeconds,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track form abandonment
export function trackFormAbandonment(formName: string, lastFieldTouched?: string) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'form_abandonment', {
      event_category: 'Form Analytics',
      event_label: formName,
      last_field: lastFieldTouched,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track admin actions
export function trackAdminAction(action: string, details?: Record<string, any>) {
  if (typeof gtag !== 'undefined') {
    gtag('event', action, {
      event_category: 'Admin',
      ...details,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track error events
export function trackError(error: Error, context?: string) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'error', {
      event_category: 'Errors',
      event_label: context || 'Application Error',
      description: error.message,
      stack: error.stack,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track API errors
export function trackAPIError(endpoint: string, error: Error, details?: Record<string, any>) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'api_error', {
      event_category: 'API Errors',
      event_label: endpoint,
      error_message: error.message,
      ...details,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track user engagement
export function trackEngagement(action: string, details?: Record<string, any>) {
  if (typeof gtag !== 'undefined') {
    gtag('event', action, {
      event_category: 'Engagement',
      ...details,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}

// Track admin navigation
export function trackAdminNavigation(from: string, to: string) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'admin_navigation', {
      event_category: 'Admin',
      from_page: from,
      to_page: to,
      send_to: 'G-K3WG1FWYTT'
    });
  }
}