import { supabase } from './supabase';
import { trackError } from './analytics';
import { handleIntegrationError } from './errorHandling';

interface ContactFormData {
  name: string;
  email: string;
  phone?: string;
  message: string;
  isEmergency?: boolean;
}

export async function sendToMake(formData: ContactFormData): Promise<void> {
  try {
    // Get Make settings with fallback to environment variable
    const { data: settings, error } = await supabase
      .from('website_settings')
      .select('value')
      .eq('key', 'make')
      .single();

    if (error && error.code !== 'PGRST116') {
      console.error('Error fetching Make settings:', error);
    }

    // Get webhook URL from settings or environment variable
    const makeWebhookUrl = settings?.value?.webhook_url || process.env.VITE_MAKE_WEBHOOK_URL;
    
    if (!makeWebhookUrl) {
      throw new Error('Make webhook URL not configured. Please contact support.');
    }

    // Format data for Make
    const formattedData = {
      contact: {
        name: formData.name.trim(),
        email: formData.email.trim().toLowerCase(),
        phone: formData.phone?.trim() || 'Not provided'
      },
      message: formData.message.trim(),
      isEmergency: formData.isEmergency || false,
      metadata: {
        timestamp: new Date().toISOString(),
        source: 'Website Contact Form',
        siteUrl: window.location.origin,
        userAgent: navigator.userAgent,
        environment: process.env.NODE_ENV
      }
    };

    // Send data to Make webhook with proper error handling
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout

    try {
      const response = await fetch(makeWebhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        body: JSON.stringify(formattedData),
        signal: controller.signal,
        mode: 'cors'
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      return;
    } catch (fetchError) {
      clearTimeout(timeoutId);
      if (fetchError.name === 'AbortError') {
        throw new Error('Request timed out. Please try again.');
      }
      throw fetchError;
    }
  } catch (error) {
    console.error('Make webhook error:', error);
    throw handleIntegrationError(error, 'Make');
  }
}