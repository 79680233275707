import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title: string;
  description: string;
  type?: string;
  name?: string;
  image?: string;
  path?: string;
  keywords?: string[];
  location?: {
    city?: string;
    state?: string;
    zip?: string;
  };
}

export function SEO({ 
  title, 
  description, 
  type = 'website',
  name = 'XXL IT Services',
  image = 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?auto=format&fit=crop&q=80',
  path = '',
  keywords = [],
  location = {
    city: 'Meridian',
    state: 'ID',
    zip: '83646'
  }
}: SEOProps) {
  const domain = 'xxlservices.com';
  const siteUrl = `https://${domain}`;
  const url = `${siteUrl}${path}`;
  
  // Default keywords for all pages
  const defaultKeywords = [
    'IT Services',
    'Computer Support',
    'Network Solutions',
    'Cybersecurity',
    'Business IT Support',
    'Managed IT Services',
    'Tech Support',
    'IT Company',
    location.city,
    'Idaho',
    'Treasure Valley'
  ];

  // Combine default and page-specific keywords
  const allKeywords = [...new Set([...defaultKeywords, ...keywords])].join(', ');
  
  // Business information
  const businessInfo = {
    name: "XXL IT Services",
    address: {
      streetAddress: "1624 E Territory Dr",
      addressLocality: location.city || "Meridian",
      addressRegion: location.state || "ID",
      postalCode: location.zip || "83646",
      addressCountry: "US"
    },
    geo: {
      latitude: 43.6087,
      longitude: -116.3515
    },
    telephone: "+1 (208) 328-4500",
    email: "info@xxlservices.com",
    url: siteUrl,
    areaServed: ["Meridian", "Boise", "Nampa", "Eagle", "Star", "Kuna", "Garden City", "Caldwell"],
    openingHours: "Mo,Tu,We,Th,Fr 09:00-17:00",
    priceRange: "$$",
    sameAs: [
      "https://www.facebook.com/xxlservices",
      "https://www.linkedin.com/company/xxlservices",
      "https://www.google.com/maps?cid=your_google_business_id"
    ]
  };

  // Structured data for local business
  const structuredData = {
    "@context": "https://schema.org",
    "@type": type === 'website' ? "LocalBusiness" : type,
    "@id": siteUrl,
    "url": url,
    ...businessInfo,
    "image": image,
    "description": description,
    "hasMap": "https://www.google.com/maps?cid=your_google_business_id",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5.0",
      "reviewCount": "23"
    },
    "paymentAccepted": ["Credit Card", "Debit Card", "Check"],
    "currenciesAccepted": "USD"
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{`${title} | ${name} - IT Services in ${location.city}, ${location.state}`}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={allKeywords} />
      <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      
      {/* Geo Tags */}
      <meta name="geo.region" content={`US-${location.state}`} />
      <meta name="geo.placename" content={location.city} />
      <meta name="geo.position" content={`${businessInfo.geo.latitude};${businessInfo.geo.longitude}`} />
      <meta name="ICBM" content={`${businessInfo.geo.latitude}, ${businessInfo.geo.longitude}`} />
      
      {/* Language */}
      <meta property="og:locale" content="en_US" />
      <meta name="language" content="English" />

      {/* Canonical URL */}
      <link rel="canonical" href={url} />

      {/* Open Graph */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={`${title} | ${name} - IT Services in ${location.city}, ${location.state}`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={name} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${title} | ${name} - IT Services in ${location.city}, ${location.state}`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Schema.org Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
}