import { supabase } from './supabase';

interface SEOAnalysisResult {
  metrics: {
    performance: number;
    accessibility: number;
    seo: number;
    bestPractices: number;
  };
  issues: Array<{
    type: 'warning' | 'error';
    message: string;
  }>;
}

export async function analyzePage(path: string): Promise<SEOAnalysisResult> {
  try {
    // Get page SEO settings
    const { data: seoSettings, error: seoError } = await supabase
      .from('seo_settings')
      .select('*')
      .eq('page_path', path)
      .single();

    if (seoError) throw seoError;

    const issues: SEOAnalysisResult['issues'] = [];
    let seoScore = 100;
    let performanceScore = 100;
    let accessibilityScore = 100;
    let bestPracticesScore = 100;

    // Title analysis
    if (!seoSettings.title) {
      issues.push({ type: 'error', message: 'Missing meta title' });
      seoScore -= 20;
    } else {
      if (seoSettings.title.length < 30) {
        issues.push({ type: 'warning', message: 'Meta title is too short (recommended: 30-60 characters)' });
        seoScore -= 5;
      } else if (seoSettings.title.length > 60) {
        issues.push({ type: 'warning', message: 'Meta title is too long (recommended: 30-60 characters)' });
        seoScore -= 5;
      }
    }

    // Description analysis
    if (!seoSettings.description) {
      issues.push({ type: 'error', message: 'Missing meta description' });
      seoScore -= 20;
    } else {
      if (seoSettings.description.length < 120) {
        issues.push({ type: 'warning', message: 'Meta description is too short (recommended: 120-160 characters)' });
        seoScore -= 5;
      } else if (seoSettings.description.length > 160) {
        issues.push({ type: 'warning', message: 'Meta description is too long (recommended: 120-160 characters)' });
        seoScore -= 5;
      }
    }

    // Keywords analysis
    if (!seoSettings.keywords || seoSettings.keywords.length === 0) {
      issues.push({ type: 'warning', message: 'No keywords specified' });
      seoScore -= 10;
    }

    // Schema markup analysis
    if (!seoSettings.schema_markup || Object.keys(seoSettings.schema_markup).length === 0) {
      issues.push({ type: 'warning', message: 'No schema markup implemented' });
      seoScore -= 10;
    }

    // Save analysis results
    const { error: analysisError } = await supabase
      .from('seo_analysis')
      .upsert({
        page_path: path,
        score: seoScore,
        issues,
        metrics: {
          performance: performanceScore,
          accessibility: accessibilityScore,
          bestPractices: bestPracticesScore,
          seo: seoScore
        }
      });

    if (analysisError) throw analysisError;

    return {
      metrics: {
        performance: performanceScore,
        accessibility: accessibilityScore,
        seo: seoScore,
        bestPractices: bestPracticesScore
      },
      issues
    };
  } catch (error) {
    console.error('Error analyzing page:', error);
    throw error;
  }
}

export async function generateSitemap(): Promise<string> {
  try {
    // Get all published pages
    const { data: pages, error } = await supabase
      .from('seo_settings')
      .select('page_path, updated_at')
      .order('page_path');

    if (error) throw error;

    const domain = 'https://xxlservices.com';
    const date = new Date().toISOString().split('T')[0];

    const xml = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${pages?.map(page => `
  <url>
    <loc>${domain}${page.page_path}</loc>
    <lastmod>${page.updated_at ? new Date(page.updated_at).toISOString().split('T')[0] : date}</lastmod>
    <changefreq>${page.page_path === '/' ? 'daily' : 'weekly'}</changefreq>
    <priority>${page.page_path === '/' ? '1.0' : '0.8'}</priority>
  </url>`).join('\n  ')}
</urlset>`;

    return xml;
  } catch (error) {
    console.error('Error generating sitemap:', error);
    throw error;
  }
}