// Error types
export enum ErrorType {
  VALIDATION = 'validation',
  API = 'api',
  NETWORK = 'network',
  DATABASE = 'database',
  AUTHENTICATION = 'authentication',
  AUTHORIZATION = 'authorization',
  RATE_LIMIT = 'rate_limit',
  INTEGRATION = 'integration',
  UNKNOWN = 'unknown'
}

// Error severity levels
export enum ErrorSeverity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical'
}

// Custom error class
export class AppError extends Error {
  type: ErrorType;
  severity: ErrorSeverity;
  context?: Record<string, any>;

  constructor(
    message: string,
    type: ErrorType = ErrorType.UNKNOWN,
    severity: ErrorSeverity = ErrorSeverity.MEDIUM,
    context?: Record<string, any>
  ) {
    super(message);
    this.name = 'AppError';
    this.type = type;
    this.severity = severity;
    this.context = context;
  }
}

// Error handling utilities
export function handleError(error: unknown, context?: string): AppError {
  if (error instanceof AppError) {
    return error;
  }

  if (error instanceof Error) {
    // Determine error type based on error message or properties
    let type = ErrorType.UNKNOWN;
    let severity = ErrorSeverity.MEDIUM;

    if (error.message.includes('network')) {
      type = ErrorType.NETWORK;
      severity = ErrorSeverity.HIGH;
    } else if (error.message.includes('validation')) {
      type = ErrorType.VALIDATION;
      severity = ErrorSeverity.LOW;
    } else if (error.message.includes('database')) {
      type = ErrorType.DATABASE;
      severity = ErrorSeverity.HIGH;
    } else if (error.message.includes('auth')) {
      type = ErrorType.AUTHENTICATION;
      severity = ErrorSeverity.HIGH;
    } else if (error.message.includes('permission')) {
      type = ErrorType.AUTHORIZATION;
      severity = ErrorSeverity.HIGH;
    } else if (error.message.includes('rate limit')) {
      type = ErrorType.RATE_LIMIT;
      severity = ErrorSeverity.MEDIUM;
    }

    return new AppError(error.message, type, severity, { originalError: error, context });
  }

  // Handle unknown errors
  return new AppError(
    'An unexpected error occurred',
    ErrorType.UNKNOWN,
    ErrorSeverity.HIGH,
    { originalError: error, context }
  );
}

// Integration error handling
export function handleIntegrationError(error: unknown, service: string): AppError {
  const appError = handleError(error, `Integration Error: ${service}`);
  appError.type = ErrorType.INTEGRATION;
  appError.severity = ErrorSeverity.HIGH;
  return appError;
}