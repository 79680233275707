import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { ITSupportPage } from './pages/services/ITSupportPage';
import { CybersecurityPage } from './pages/services/CybersecurityPage';
import { Microsoft365Page } from './pages/services/Microsoft365Page';
import { NetworkPage } from './pages/services/NetworkPage';
import { ProjectsPage } from './pages/services/ProjectsPage';
import { SecurityCamerasPage } from './pages/services/SecurityCamerasPage';
import { CablingPage } from './pages/services/CablingPage';
import { ComputerRepairPage } from './pages/services/ComputerRepairPage';
import { WebServicesPage } from './pages/services/WebServicesPage';
import { ContactPage } from './pages/ContactPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { TermsPage } from './pages/TermsPage';
import { AdminProvider } from './contexts/AdminContext';
import { AdminLayout } from './components/admin/AdminLayout';
import { LoginPage } from './pages/admin/LoginPage';
import { DashboardPage } from './pages/admin/DashboardPage';
import { MakePage } from './pages/admin/MakePage';
import { UsersPage } from './pages/admin/UsersPage';
import { SEOPage } from './pages/admin/SEOPage';
import { AnalyticsPage } from './pages/admin/AnalyticsPage';
import { AnalyticsSettingsPage } from './pages/admin/AnalyticsSettingsPage';
import { HomePage } from './pages/HomePage';

const PublicRoute = ({ children }: { children: React.ReactNode }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

export default function App() {
  return (
    <AdminProvider>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<PublicRoute><HomePage /></PublicRoute>} />
        <Route path="/services/it-support" element={<PublicRoute><ITSupportPage /></PublicRoute>} />
        <Route path="/services/cybersecurity" element={<PublicRoute><CybersecurityPage /></PublicRoute>} />
        <Route path="/services/microsoft-365" element={<PublicRoute><Microsoft365Page /></PublicRoute>} />
        <Route path="/services/network" element={<PublicRoute><NetworkPage /></PublicRoute>} />
        <Route path="/services/projects" element={<PublicRoute><ProjectsPage /></PublicRoute>} />
        <Route path="/services/security-cameras" element={<PublicRoute><SecurityCamerasPage /></PublicRoute>} />
        <Route path="/services/cabling" element={<PublicRoute><CablingPage /></PublicRoute>} />
        <Route path="/services/computer-repair" element={<PublicRoute><ComputerRepairPage /></PublicRoute>} />
        <Route path="/services/web-development" element={<PublicRoute><WebServicesPage /></PublicRoute>} />
        <Route path="/contact" element={<PublicRoute><ContactPage /></PublicRoute>} />
        <Route path="/privacy" element={<PublicRoute><PrivacyPage /></PublicRoute>} />
        <Route path="/terms" element={<PublicRoute><TermsPage /></PublicRoute>} />

        {/* Admin Routes */}
        <Route path="/admin/login" element={<LoginPage />} />
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path="make" element={<MakePage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="seo" element={<SEOPage />} />
          <Route path="analytics" element={<AnalyticsPage />} />
          <Route path="analytics/settings" element={<AnalyticsSettingsPage />} />
        </Route>

        {/* Catch all route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AdminProvider>
  );
}