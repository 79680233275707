import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  LayoutDashboard, Webhook, 
  Users, Search, BarChart 
} from 'lucide-react';

const navigation = [
  { name: 'Dashboard', href: '/admin', icon: LayoutDashboard },
  { name: 'Make', href: '/admin/make', icon: Webhook },
  { name: 'Users', href: '/admin/users', icon: Users },
  { name: 'SEO', href: '/admin/seo', icon: Search },
  { name: 'Analytics', href: '/admin/analytics', icon: BarChart }
];

export function AdminSidebar() {
  return (
    <aside className="w-64 bg-white dark:bg-gray-800 shadow-sm min-h-screen">
      <nav className="mt-5 px-2">
        <div className="space-y-1">
          {navigation.map((item) => {
            const Icon = item.icon;
            return (
              <NavLink
                key={item.name}
                to={item.href}
                end={item.href === '/admin'}
                className={({ isActive }) =>
                  `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                    isActive
                      ? 'bg-primary-50 dark:bg-primary-900/50 text-primary-600 dark:text-primary-400'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
                  }`
                }
              >
                <Icon className="mr-3 h-5 w-5 flex-shrink-0" />
                {item.name}
              </NavLink>
            );
          })}
        </div>
      </nav>
    </aside>
  );
}