import React from 'react';
import { Cloud, Mail, Users, Calendar } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function Microsoft365Page() {
  const features = [
    {
      icon: Mail,
      title: "Email & Communication",
      description: "Professional email hosting and communication tools."
    },
    {
      icon: Users,
      title: "Collaboration",
      description: "Team collaboration with SharePoint and Teams."
    },
    {
      icon: Calendar,
      title: "Productivity",
      description: "Enhanced productivity with Office apps and tools."
    }
  ];

  return (
    <>
      <SEO 
        title="Microsoft 365 Solutions & Support in Meridian | XXL IT Services" 
        description="Expert Microsoft 365 implementation, migration, and support services. Maximize your team's productivity with cloud-based solutions in Meridian, ID."
        path="/services/microsoft-365"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-16">
              <Cloud className="w-16 h-16 mx-auto mb-6 text-primary-600 dark:text-primary-400" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 dark:text-white">Microsoft 365 Solutions</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Empower your business with modern cloud-based productivity tools
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-lg dark:shadow-gray-900/30"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600 dark:text-primary-400" />
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{feature.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none dark:prose-invert">
              <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Complete Microsoft 365 Services</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                We provide comprehensive Microsoft 365 solutions to help your business leverage the full 
                power of cloud-based productivity tools. From implementation to ongoing support, we ensure 
                your team gets the most out of Microsoft 365.
              </p>

              <div className="bg-primary-50 dark:bg-primary-900/20 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Our Microsoft 365 Services Include:</h3>
                <ul className="space-y-4">
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Migration planning and execution</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Setup and configuration of all M365 services</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Security and compliance configuration</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>User training and adoption support</span>
                  </li>
                </ul>
              </div>

              <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Implementation Process</h3>
              <ol className="space-y-4 mb-8">
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">1.</span>
                  <span>Assessment of current environment and needs</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">2.</span>
                  <span>Development of migration strategy</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">3.</span>
                  <span>Implementation and data migration</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">4.</span>
                  <span>User training and support</span>
                </li>
              </ol>

              <div className="grid md:grid-cols-2 gap-6 mb-8">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg dark:shadow-gray-900/30">
                  <h4 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white">
                    Email & Communication
                  </h4>
                  <ul className="space-y-2">
                    <li className="text-gray-600 dark:text-gray-300">• Exchange Online</li>
                    <li className="text-gray-600 dark:text-gray-300">• Microsoft Teams</li>
                    <li className="text-gray-600 dark:text-gray-300">• SharePoint Online</li>
                  </ul>
                </div>
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg dark:shadow-gray-900/30">
                  <h4 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white">
                    Security & Compliance
                  </h4>
                  <ul className="space-y-2">
                    <li className="text-gray-600 dark:text-gray-300">• Advanced Threat Protection</li>
                    <li className="text-gray-600 dark:text-gray-300">• Data Loss Prevention</li>
                    <li className="text-gray-600 dark:text-gray-300">• Information Protection</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-12 text-center">
              <a 
                href="/contact" 
                className="inline-block bg-primary-600 hover:bg-primary-700 text-white px-8 py-4 rounded-lg text-lg font-medium transition-colors"
              >
                Start Your Microsoft 365 Journey
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}