import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Webhook, Save, Eye, EyeOff, Send } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { makeConfigSchema, type MakeConfig } from '../../types/admin';
import { sendToMake } from '../../lib/make';
import toast from 'react-hot-toast';
import { trackAdminAction, trackError } from '../../lib/analytics';

const defaultMakeConfig: MakeConfig = {
  enabled: false,
  webhook_url: '',
  secret: ''
};

export function MakePage() {
  const [showSecret, setShowSecret] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [testData, setTestData] = React.useState('');
  const [isTesting, setIsTesting] = React.useState(false);

  const { register, handleSubmit, formState: { errors, isDirty }, reset, watch, getValues } = useForm<MakeConfig>({
    resolver: zodResolver(makeConfigSchema),
    defaultValues: defaultMakeConfig
  });

  const enabled = watch('enabled');

  // Fetch Make settings
  React.useEffect(() => {
    async function fetchSettings() {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('website_settings')
          .select('value')
          .eq('key', 'make')
          .maybeSingle();

        if (error) throw error;
        
        // If we have data, use it, otherwise use defaults
        const config = data?.value as MakeConfig || defaultMakeConfig;
        reset(config);
      } catch (error) {
        console.error('Error fetching Make settings:', error);
        toast.error('Failed to load Make settings');
        // Reset to defaults on error
        reset(defaultMakeConfig);
      } finally {
        setLoading(false);
      }
    }

    fetchSettings();
  }, [reset]);

  const onSubmit = async (data: MakeConfig) => {
    try {
      // First check if the record exists
      const { data: existing } = await supabase
        .from('website_settings')
        .select('id')
        .eq('key', 'make')
        .single();

      let error;
      
      if (existing) {
        // Update existing record
        const result = await supabase
          .from('website_settings')
          .update({ value: data })
          .eq('key', 'make');
        error = result.error;
      } else {
        // Insert new record
        const result = await supabase
          .from('website_settings')
          .insert({ key: 'make', value: data });
        error = result.error;
      }

      if (error) throw error;
      
      toast.success('Make settings saved successfully');
      trackAdminAction('update_make_settings', {
        enabled: data.enabled,
        has_webhook: !!data.webhook_url,
        has_secret: !!data.secret
      });
    } catch (error) {
      console.error('Error saving Make settings:', error);
      toast.error('Failed to save Make settings');
      trackError(error instanceof Error ? error : new Error('Failed to save Make settings'));
    }
  };

  const handleTestWebhook = async () => {
    if (!testData) {
      toast.error('Please enter test data');
      return;
    }

    setIsTesting(true);
    try {
      const config = getValues();
      await sendToMake({
        name: 'Test User',
        email: 'test@example.com',
        phone: '555-0123',
        message: testData
      });
      toast.success('Test data sent successfully! Check your Make scenario.');
      trackAdminAction('test_make_webhook', { success: true });
    } catch (error) {
      console.error('Error testing webhook:', error);
      toast.error('Failed to send test data. Please check your configuration.');
      trackError(error instanceof Error ? error : new Error('Failed to test Make webhook'));
      trackAdminAction('test_make_webhook', { 
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    } finally {
      setIsTesting(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse space-y-4">
        <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/4" />
        <div className="h-[400px] bg-gray-200 dark:bg-gray-700 rounded" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Make Integration</h1>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                {...register('enabled')}
                className="rounded border-gray-300 dark:border-gray-600 text-primary-600 shadow-sm focus:ring-primary-500 dark:bg-gray-700"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">
                Enable Make integration
              </span>
            </label>
          </div>

          {enabled && (
            <>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Webhook URL
                  </label>
                  <input
                    type="text"
                    {...register('webhook_url')}
                    placeholder="https://hook.make.com/..."
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
                  />
                  {errors.webhook_url && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                      {errors.webhook_url.message}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Webhook Secret (Optional)
                  </label>
                  <div className="relative">
                    <input
                      type={showSecret ? 'text' : 'password'}
                      {...register('secret')}
                      placeholder="Enter webhook secret"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 pr-10"
                    />
                    <button
                      type="button"
                      onClick={() => setShowSecret(!showSecret)}
                      className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    >
                      {showSecret ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                    </button>
                  </div>
                  {errors.secret && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                      {errors.secret.message}
                    </p>
                  )}
                </div>

                {/* Test Webhook Section */}
                <div className="mt-8 p-6 bg-gray-50 dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4 flex items-center">
                    <Webhook className="w-5 h-5 mr-2" />
                    Test Webhook
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Test Message
                      </label>
                      <textarea
                        value={testData}
                        onChange={(e) => setTestData(e.target.value)}
                        placeholder="Enter test message"
                        rows={3}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={handleTestWebhook}
                      disabled={isTesting || !enabled}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <Send className="w-4 h-4 mr-2" />
                      {isTesting ? 'Sending...' : 'Send Test'}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={!isDirty}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Save className="w-4 h-4 mr-2" />
              Save Settings
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}