import React from 'react';
import { Network, Wifi, Server, Shield, Phone, Cloud, Globe, Lock } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function NetworkPage() {
  const services = [
    {
      icon: Wifi,
      title: "Network Design",
      description: "Custom network architecture design and implementation."
    },
    {
      icon: Server,
      title: "Infrastructure",
      description: "Robust network infrastructure setup and maintenance."
    },
    {
      icon: Shield,
      title: "Security",
      description: "Advanced network security and monitoring."
    },
    {
      icon: Phone,
      title: "Phone Systems",
      description: "VoIP and unified communications solutions."
    }
  ];

  const features = [
    {
      icon: Cloud,
      title: "Cloud Integration",
      description: "Seamless integration with cloud services and applications"
    },
    {
      icon: Globe,
      title: "SD-WAN",
      description: "Software-defined networking for optimal performance"
    },
    {
      icon: Lock,
      title: "Network Security",
      description: "Enterprise-grade security protocols and monitoring"
    },
    {
      icon: Phone,
      title: "VoIP Systems",
      description: "Modern business communication solutions"
    }
  ];

  return (
    <>
      <SEO 
        title="Professional Network Solutions in Meridian | XXL IT Services" 
        description="Expert network infrastructure and phone system solutions. Build a robust and secure network with modern VoIP communications for your Meridian business."
        path="/services/network"
        keywords={[
          'Network Solutions',
          'Business Phone Systems',
          'VoIP',
          'Network Security',
          'SD-WAN',
          'Cloud Integration',
          'Network Infrastructure',
          'Meridian ID'
        ]}
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            {/* Hero Section */}
            <div className="text-center mb-16">
              <Network className="w-16 h-16 mx-auto mb-6 text-primary-600 dark:text-primary-400" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 dark:text-white">Network Solutions</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Build a robust network infrastructure with modern communication systems
              </p>
            </div>

            {/* Services Grid */}
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
              {services.map((service, index) => {
                const Icon = service.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600 dark:text-primary-400" />
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{service.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{service.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none dark:prose-invert">
              {/* Main Content */}
              <div className="bg-gradient-to-br from-gray-50 to-primary-50 dark:from-gray-900 dark:to-primary-900/20 p-8 rounded-2xl mb-8">
                <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Enterprise Network Solutions</h2>
                <p className="text-gray-700 dark:text-gray-300 mb-6">
                  Our network solutions are designed to provide reliable, secure, and scalable connectivity 
                  for modern businesses. From basic setups to complex enterprise networks, we deliver 
                  solutions that grow with your business.
                </p>
                <div className="grid md:grid-cols-2 gap-6">
                  {features.map((feature, index) => {
                    const Icon = feature.icon;
                    return (
                      <div key={index} className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                        <div className="flex items-center mb-4">
                          <Icon className="w-6 h-6 text-primary-600 dark:text-primary-400 mr-3" />
                          <h3 className="text-lg font-semibold">{feature.title}</h3>
                        </div>
                        <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Phone Systems Section */}
              <div className="bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 p-8 rounded-2xl mb-8">
                <div className="flex items-center mb-4">
                  <Phone className="w-8 h-8 text-primary-600 dark:text-primary-400 mr-3" />
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-white">Business Phone Systems</h3>
                </div>
                <p className="text-gray-700 dark:text-gray-300 mb-6">
                  Transform your business communications with our modern VoIP phone systems. Get enterprise-grade features with the flexibility of cloud-based solutions.
                </p>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                    <h4 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white">System Features</h4>
                    <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Cloud-based phone system
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Auto-attendant & IVR
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Call queuing & routing
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Voicemail to email
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Mobile integration
                      </li>
                    </ul>
                  </div>
                  <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                    <h4 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white">Benefits</h4>
                    <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Reduced communication costs
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Remote work support
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Scalable solutions
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Advanced call analytics
                      </li>
                      <li className="flex items-center">
                        <span className="w-2 h-2 bg-primary-600 dark:bg-primary-400 rounded-full mr-2"></span>
                        Disaster recovery
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Call to Action */}
              <div className="mt-12 text-center">
                <a 
                  href="/contact" 
                  className="inline-block bg-primary-600 hover:bg-primary-700 text-white px-8 py-4 rounded-lg text-lg font-medium transition-colors"
                >
                  Get Started with Network Solutions
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}