import React from 'react';
import { Monitor, HeartPulse, Clock, Users, AlertTriangle, Laptop, Server, Shield, Wifi } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function ITSupportPage() {
  const services = [
    {
      icon: Server,
      title: "Server & Network Failures",
      description: "Immediate response to critical server outages and network connectivity issues"
    },
    {
      icon: Shield,
      title: "Cybersecurity Incidents",
      description: "Rapid containment and resolution of security breaches and cyber attacks"
    },
    {
      icon: Laptop,
      title: "Hardware Failures",
      description: "Emergency repair and replacement of critical business hardware"
    },
    {
      icon: Wifi,
      title: "Connectivity Issues",
      description: "Swift restoration of internet and network connectivity"
    }
  ];

  const features = [
    {
      icon: HeartPulse,
      title: "24/7 System Monitoring",
      description: "Continuous monitoring of your IT infrastructure to prevent issues before they occur."
    },
    {
      icon: Clock,
      title: "Rapid Response Time",
      description: "Quick resolution of IT issues with our dedicated support team."
    },
    {
      icon: Users,
      title: "Expert Team",
      description: "Certified IT professionals with years of industry experience."
    }
  ];

  return (
    <>
      <SEO 
        title="24/7 IT Support Services in Meridian, ID | XXL IT Services" 
        description="Professional IT support services with 24/7 monitoring and rapid response time. Expert technical support for businesses in Meridian and the Treasure Valley."
        path="/services/it-support"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-16">
              <Monitor className="w-16 h-16 mx-auto mb-6 text-primary-600 dark:text-primary-400" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 dark:text-white">IT Support Services</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Comprehensive IT support solutions to keep your business running smoothly
              </p>
            </div>

            {/* Emergency Support Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mb-16 bg-gradient-to-br from-red-50 to-orange-50 dark:from-red-950/50 dark:to-orange-950/50 rounded-3xl p-8 border border-red-100 dark:border-red-900"
            >
              <div className="flex items-center gap-4 mb-6">
                <AlertTriangle className="w-8 h-8 text-red-600 dark:text-red-500" />
                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">24/7 Emergency IT Support</h2>
              </div>
              
              <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                Every minute of downtime costs your business money. Our emergency IT support ensures your critical systems stay operational 24/7/365, with rapid response times and expert resolution of urgent technical issues.
              </p>

              <div className="grid md:grid-cols-2 gap-6 mb-8">
                {services.map((service, index) => {
                  const Icon = service.icon;
                  return (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 + index * 0.1 }}
                      className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg dark:shadow-gray-900/30"
                    >
                      <Icon className="w-8 h-8 text-red-600 dark:text-red-500 mb-4" />
                      <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{service.title}</h3>
                      <p className="text-gray-600 dark:text-gray-300">{service.description}</p>
                    </motion.div>
                  );
                })}
              </div>

              <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg dark:shadow-gray-900/30 mb-8">
                <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Our Emergency Response Guarantee</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                    <Clock className="w-5 h-5 text-red-600 dark:text-red-500" />
                    <span>15-minute response time for critical issues</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                    <Users className="w-5 h-5 text-red-600 dark:text-red-500" />
                    <span>Dedicated emergency response team</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                    <HeartPulse className="w-5 h-5 text-red-600 dark:text-red-500" />
                    <span>24/7/365 availability</span>
                  </li>
                  <li className="flex items-center gap-2 text-gray-600 dark:text-gray-300">
                    <Shield className="w-5 h-5 text-red-600 dark:text-red-500" />
                    <span>Priority handling for business-critical systems</span>
                  </li>
                </ul>
              </div>

              <div className="text-center">
                <a 
                  href="/contact"
                  className="inline-flex items-center gap-2 bg-red-600 hover:bg-red-700 text-white px-8 py-4 rounded-xl font-semibold transition-colors shadow-lg hover:shadow-xl"
                >
                  <AlertTriangle className="w-5 h-5" />
                  <span>Get Emergency Support Now</span>
                </a>
              </div>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-lg dark:shadow-gray-900/30"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600 dark:text-primary-400" />
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{feature.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none dark:prose-invert">
              <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Why Choose Our IT Support?</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Our IT support service is designed to provide comprehensive coverage for all your technology needs. 
                From routine maintenance to emergency support, we ensure your systems are always operating at peak performance.
              </p>

              <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Our Support Includes:</h3>
              <ul className="space-y-4 mb-8">
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">•</span>
                  <span>Remote and on-site technical support</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">•</span>
                  <span>Network monitoring and maintenance</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">•</span>
                  <span>Security updates and patch management</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">•</span>
                  <span>Data backup and recovery solutions</span>
                </li>
              </ul>

              <div className="bg-primary-50 dark:bg-primary-900/20 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Response Time Guarantee</h3>
                <p className="text-gray-700 dark:text-gray-300">
                  We guarantee a response time of under 1 hour for critical issues, ensuring your business 
                  experiences minimal downtime. Our team is available 24/7 to handle any IT emergencies.
                </p>
              </div>
            </div>

            <div className="mt-12 text-center">
              <a 
                href="/contact" 
                className="inline-block bg-primary-600 hover:bg-primary-700 text-white px-8 py-4 rounded-lg text-lg font-medium transition-colors"
              >
                Get Started with IT Support
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}