import React from 'react';
import { motion } from 'framer-motion';
import { Send } from 'lucide-react';
import { SEO } from '../components/layout/SEO';
import { LocalBusiness } from '../components/LocalBusiness';
import { sendToMake } from '../lib/make';
import { trackFormSubmission, trackFormError, trackFormInteraction } from '../lib/analytics';
import { z } from 'zod';
import toast from 'react-hot-toast';

const contactFormSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  phone: z.string().min(1, "Phone number is required"),
  message: z.string().min(10, "Message must be at least 10 characters long"),
  isEmergency: z.boolean().default(false)
});

type ContactForm = z.infer<typeof contactFormSchema>;

export function ContactPage() {
  const [formState, setFormState] = React.useState<ContactForm>({
    name: '',
    email: '',
    phone: '',
    message: '',
    isEmergency: false
  });

  const [status, setStatus] = React.useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const [errors, setErrors] = React.useState<Partial<Record<keyof ContactForm, string>>>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (status === 'submitting') return;

    setStatus('submitting');
    setErrors({});

    try {
      // Track form submission attempt
      trackFormInteraction('contact', 'submit');

      // Validate form data
      const validatedData = contactFormSchema.parse(formState);

      // Send to Make webhook
      await sendToMake(validatedData);

      // Reset form and show success message
      setStatus('success');
      setFormState({
        name: '',
        email: '',
        phone: '',
        message: '',
        isEmergency: false
      });

      toast.success('Thank you for your message. We will get back to you soon!');
      trackFormSubmission('contact', true);

      if (validatedData.isEmergency) {
        toast.success('For immediate assistance, please call (208) 328-4500');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      
      if (error instanceof z.ZodError) {
        const fieldErrors: Partial<Record<keyof ContactForm, string>> = {};
        error.errors.forEach((err) => {
          if (err.path.length > 0) {
            fieldErrors[err.path[0] as keyof ContactForm] = err.message;
          }
        });
        setErrors(fieldErrors);
        trackFormError('contact', 'validation', fieldErrors);
      } else {
        const message = error instanceof Error ? error.message : 'An unexpected error occurred. Please try again.';
        toast.error(message);
        trackFormError('contact', 'submission', { error: message });
      }
      
      setStatus('error');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, type, value, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormState(prev => ({
      ...prev,
      [name]: newValue
    }));

    // Track field interaction
    trackFormInteraction('contact', 'change', { field: name });

    if (errors[name as keyof ContactForm]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
    if (status === 'success' || status === 'error') {
      setStatus('idle');
    }
  };

  return (
    <>
      <SEO 
        title="Contact Us" 
        description="Get in touch with us for professional IT solutions. We're here to help with all your technology needs."
        path="/contact"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-6xl mx-auto"
          >
            <div className="text-center mb-16">
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Get in Touch</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                We're here to help with all your technology needs
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-12">
              {/* Contact Form */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8"
              >
                <form onSubmit={handleSubmit} className="space-y-6">
                  {/* Emergency Checkbox */}
                  <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg">
                    <label className="flex items-start space-x-2">
                      <input
                        type="checkbox"
                        name="isEmergency"
                        checked={formState.isEmergency}
                        onChange={handleChange}
                        className="mt-1 rounded border-red-300 text-red-600 shadow-sm focus:ring-red-500"
                      />
                      <div>
                        <span className="font-medium text-red-700 dark:text-red-400">Is this an emergency?</span>
                        {formState.isEmergency && (
                          <div className="mt-2 text-sm text-red-600 dark:text-red-400">
                            For immediate assistance, please call us at:
                            <a href="tel:+12083284500" className="block font-bold text-lg mt-1">
                              (208) 328-4500
                            </a>
                            Our emergency support team is available 24/7 to help you with urgent technical issues.
                          </div>
                        )}
                      </div>
                    </label>
                  </div>

                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formState.name}
                      onChange={handleChange}
                      className={`w-full px-4 py-2 rounded-lg border ${
                        errors.name 
                          ? 'border-red-500 dark:border-red-400' 
                          : 'border-gray-300 dark:border-gray-600'
                      } focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent dark:bg-gray-700 dark:text-white`}
                    />
                    {errors.name && (
                      <p className="mt-1 text-sm text-red-500 dark:text-red-400">{errors.name}</p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Email *
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formState.email}
                      onChange={handleChange}
                      className={`w-full px-4 py-2 rounded-lg border ${
                        errors.email 
                          ? 'border-red-500 dark:border-red-400' 
                          : 'border-gray-300 dark:border-gray-600'
                      } focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent dark:bg-gray-700 dark:text-white`}
                    />
                    {errors.email && (
                      <p className="mt-1 text-sm text-red-500 dark:text-red-400">{errors.email}</p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Phone Number *
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formState.phone}
                      onChange={handleChange}
                      className={`w-full px-4 py-2 rounded-lg border ${
                        errors.phone 
                          ? 'border-red-500 dark:border-red-400' 
                          : 'border-gray-300 dark:border-gray-600'
                      } focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent dark:bg-gray-700 dark:text-white`}
                    />
                    {errors.phone && (
                      <p className="mt-1 text-sm text-red-500 dark:text-red-400">{errors.phone}</p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Message *
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={formState.message}
                      onChange={handleChange}
                      rows={4}
                      className={`w-full px-4 py-2 rounded-lg border ${
                        errors.message 
                          ? 'border-red-500 dark:border-red-400' 
                          : 'border-gray-300 dark:border-gray-600'
                      } focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 focus:border-transparent dark:bg-gray-700 dark:text-white`}
                    />
                    {errors.message && (
                      <p className="mt-1 text-sm text-red-500 dark:text-red-400">{errors.message}</p>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={status === 'submitting'}
                    className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors flex items-center justify-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <span>{status === 'submitting' ? 'Sending...' : 'Send Message'}</span>
                    <Send className="w-4 h-4" />
                  </button>
                </form>
              </motion.div>

              {/* Contact Information */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
              >
                <LocalBusiness />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}