import React from 'react';
import { motion } from 'framer-motion';
import { 
  LineChart, Line, AreaChart, Area, BarChart, Bar,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer 
} from 'recharts';
import { 
  Users, Activity, Clock, AlertTriangle, Search,
  TrendingUp, DollarSign, Shield, Settings
} from 'lucide-react';

interface DateRange {
  start: string;
  end: string;
}

interface AnalyticsData {
  pageViews: {
    total: number;
    unique: number;
    trend: number[];
  };
  sessions: {
    total: number;
    avgDuration: number;
    bounceRate: number;
    trend: number[];
  };
  users: {
    total: number;
    new: number;
    returning: number;
    trend: number[];
  };
  events: {
    total: number;
    byCategory: Record<string, number>;
    trend: number[];
  };
}

export function GoogleAnalytics() {
  const [dateRange, setDateRange] = React.useState<DateRange>({
    start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
  });

  const [data, setData] = React.useState<AnalyticsData>({
    pageViews: {
      total: 25000,
      unique: 15000,
      trend: [1200, 1300, 1100, 1400, 1200, 1500, 1300]
    },
    sessions: {
      total: 12000,
      avgDuration: 180,
      bounceRate: 45,
      trend: [600, 650, 550, 700, 600, 750, 650]
    },
    users: {
      total: 8000,
      new: 5000,
      returning: 3000,
      trend: [400, 450, 400, 500, 450, 550, 500]
    },
    events: {
      total: 35000,
      byCategory: {
        'Form Submission': 1200,
        'Button Click': 15000,
        'Page View': 18000,
        'File Download': 800
      },
      trend: [1800, 1900, 1700, 2000, 1800, 2100, 1900]
    }
  });

  return (
    <div className="space-y-6">
      {/* Date Range Selector */}
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Google Analytics</h2>
        <div className="flex items-center space-x-2">
          <input
            type="date"
            value={dateRange.start}
            onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
            className="px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
          />
          <span className="text-gray-500">to</span>
          <input
            type="date"
            value={dateRange.end}
            onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
            className="px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
          />
        </div>
      </div>

      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Page Views</p>
              <p className="text-2xl font-semibold">{data.pageViews.total}</p>
            </div>
            <Search className="w-8 h-8 text-primary-600" />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Users</p>
              <p className="text-2xl font-semibold">{data.users.total}</p>
            </div>
            <Users className="w-8 h-8 text-green-600" />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Avg. Session</p>
              <p className="text-2xl font-semibold">{Math.floor(data.sessions.avgDuration / 60)}m</p>
            </div>
            <Clock className="w-8 h-8 text-blue-600" />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Bounce Rate</p>
              <p className="text-2xl font-semibold">{data.sessions.bounceRate}%</p>
            </div>
            <Activity className="w-8 h-8 text-purple-600" />
          </div>
        </motion.div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Page Views Trend */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <h3 className="text-lg font-medium mb-4">Page Views Trend</h3>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={data.pageViews.trend.map((value, index) => ({ day: index, value }))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="value" stroke="#3b82f6" fill="#3b82f6" fillOpacity={0.1} />
            </AreaChart>
          </ResponsiveContainer>
        </motion.div>

        {/* Users Trend */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <h3 className="text-lg font-medium mb-4">Users Trend</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data.users.trend.map((value, index) => ({ day: index, value }))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#10b981" />
            </LineChart>
          </ResponsiveContainer>
        </motion.div>

        {/* Events by Category */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow md:col-span-2"
        >
          <h3 className="text-lg font-medium mb-4">Events by Category</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={Object.entries(data.events.byCategory).map(([category, count]) => ({ category, count }))}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="category" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#3b82f6" />
            </BarChart>
          </ResponsiveContainer>
        </motion.div>
      </div>
    </div>
  );
}