import React from 'react';
import { motion } from 'framer-motion';
import { Search, TrendingUp, Globe, Link as LinkIcon } from 'lucide-react';

interface SearchAnalytics {
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
}

interface SearchTerm {
  term: string;
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
}

interface Page {
  path: string;
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
}

export function GoogleSearchConsole() {
  const [dateRange, setDateRange] = React.useState({
    start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
  });

  const [analytics, setAnalytics] = React.useState<SearchAnalytics>({
    clicks: 1250,
    impressions: 25000,
    ctr: 5,
    position: 12.5
  });

  const [topSearchTerms, setTopSearchTerms] = React.useState<SearchTerm[]>([
    { term: "it support meridian", clicks: 150, impressions: 2500, ctr: 6, position: 3.2 },
    { term: "computer repair boise", clicks: 120, impressions: 2000, ctr: 6, position: 4.1 },
    { term: "network solutions idaho", clicks: 100, impressions: 1800, ctr: 5.5, position: 4.8 }
  ]);

  const [topPages, setTopPages] = React.useState<Page[]>([
    { path: "/services/it-support", clicks: 300, impressions: 5000, ctr: 6, position: 2.8 },
    { path: "/services/computer-repair", clicks: 250, impressions: 4500, ctr: 5.5, position: 3.2 },
    { path: "/contact", clicks: 200, impressions: 4000, ctr: 5, position: 3.5 }
  ]);

  return (
    <div className="space-y-6">
      {/* Date Range Selector */}
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Search Console Analytics</h2>
        <div className="flex items-center space-x-2">
          <input
            type="date"
            value={dateRange.start}
            onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
            className="px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
          />
          <span className="text-gray-500">to</span>
          <input
            type="date"
            value={dateRange.end}
            onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
            className="px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
          />
        </div>
      </div>

      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Total Clicks</p>
              <p className="text-2xl font-semibold">{analytics.clicks}</p>
            </div>
            <Search className="w-8 h-8 text-primary-600" />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Impressions</p>
              <p className="text-2xl font-semibold">{analytics.impressions}</p>
            </div>
            <Globe className="w-8 h-8 text-green-600" />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">CTR</p>
              <p className="text-2xl font-semibold">{analytics.ctr}%</p>
            </div>
            <LinkIcon className="w-8 h-8 text-blue-600" />
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">Avg. Position</p>
              <p className="text-2xl font-semibold">{analytics.position}</p>
            </div>
            <TrendingUp className="w-8 h-8 text-purple-600" />
          </div>
        </motion.div>
      </div>

      {/* Top Search Terms */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
      >
        <h3 className="text-lg font-medium mb-4">Top Search Terms</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Search Term
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Clicks
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Impressions
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  CTR
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Position
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {topSearchTerms.map((term, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">{term.term}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{term.clicks}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{term.impressions}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{term.ctr}%</td>
                  <td className="px-6 py-4 whitespace-nowrap">{term.position}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </motion.div>

      {/* Top Pages */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow"
      >
        <h3 className="text-lg font-medium mb-4">Top Pages</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Page
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Clicks
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Impressions
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  CTR
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Position
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {topPages.map((page, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">{page.path}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{page.clicks}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{page.impressions}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{page.ctr}%</td>
                  <td className="px-6 py-4 whitespace-nowrap">{page.position}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </motion.div>
    </div>
  );
}