import { z } from 'zod';

// Login schema
export const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters')
});

export type LoginForm = z.infer<typeof loginSchema>;

// User schemas
export const userSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required')
});

export type UserForm = z.infer<typeof userSchema>;

export const userEditSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  role: z.enum(['admin', 'user']),
  password: z.string().min(8, 'Password must be at least 8 characters').optional()
});

export type UserEditForm = z.infer<typeof userEditSchema>;

// Analytics schema
export const analyticsConfigSchema = z.object({
  enabled: z.boolean(),
  googleAnalyticsId: z.string().min(1, 'Google Analytics ID is required'),
  searchConsoleVerification: z.string().min(1, 'Search Console verification code is required'),
  trackingSettings: z.object({
    pageViews: z.boolean(),
    events: z.boolean(),
    userEngagement: z.boolean(),
    formInteractions: z.boolean(),
    errorTracking: z.boolean()
  })
});

export type AnalyticsConfig = z.infer<typeof analyticsConfigSchema>;

// Make schema
export const makeConfigSchema = z.object({
  enabled: z.boolean(),
  webhook_url: z.string().min(1, 'Webhook URL is required'),
  secret: z.string().optional()
});

export type MakeConfig = z.infer<typeof makeConfigSchema>;

// SEO schema
export const seoSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required').max(160, 'Description must be 160 characters or less'),
  keywords: z.array(z.string()),
  canonical: z.string().url('Invalid canonical URL').optional(),
  robots: z.string().optional(),
  openGraph: z.object({
    title: z.string(),
    description: z.string(),
    image: z.string().url('Invalid image URL'),
    type: z.string()
  }).optional(),
  schema: z.record(z.any()).optional()
});

export type SEOSettings = z.infer<typeof seoSchema>;

// Bank hours schema
export const bankHoursSchema = z.object({
  clientId: z.string().min(1, 'Client ID is required'),
  hours: z.number().min(0, 'Hours must be positive'),
  description: z.string().min(1, 'Description is required'),
  date: z.string(),
  type: z.enum(['credit', 'debit']),
  category: z.enum(['support', 'project', 'maintenance', 'consulting']),
  notes: z.string().optional()
});

export type BankHours = z.infer<typeof bankHoursSchema>;