import React from 'react';
import { SEO } from '../components/layout/SEO';
import { motion } from 'framer-motion';
import { Scale, Shield, FileCheck, AlertTriangle, Clock, Users, Mail, Phone } from 'lucide-react';

export function TermsPage() {
  const sections = [
    {
      icon: Scale,
      title: "Agreement Terms",
      content: [
        {
          subtitle: "Acceptance of Terms",
          items: [
            "By accessing our services, you agree to these terms",
            "Terms apply to all services and website usage",
            "Must be legally capable of forming binding contracts",
            "Compliance with all applicable laws required"
          ]
        }
      ]
    },
    {
      icon: Shield,
      title: "Service Provisions",
      content: [
        {
          subtitle: "Service Delivery",
          items: [
            "Professional IT support and services",
            "24/7 emergency support availability",
            "Regular maintenance and updates",
            "Quality assurance standards"
          ]
        },
        {
          subtitle: "Service Modifications",
          items: [
            "Right to modify services with notice",
            "Service upgrades and improvements",
            "System maintenance windows",
            "Emergency service adjustments"
          ]
        }
      ]
    },
    {
      icon: FileCheck,
      title: "Client Responsibilities",
      content: [
        {
          subtitle: "Your Obligations",
          items: [
            "Provide accurate information",
            "Maintain security credentials",
            "Report issues promptly",
            "Comply with acceptable use policies"
          ]
        }
      ]
    },
    {
      icon: AlertTriangle,
      title: "Liability & Warranties",
      content: [
        {
          subtitle: "Limitations",
          items: [
            "Service provided 'as is'",
            "No implied warranties",
            "Limited liability for damages",
            "Force majeure conditions"
          ]
        }
      ]
    }
  ];

  return (
    <>
      <SEO 
        title="Terms of Service | XXL IT Services" 
        description="Read our terms of service to understand the rules, guidelines, and agreements that govern the use of XXL IT Services' website and services."
        path="/terms"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            {/* Header */}
            <div className="text-center mb-16">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.2 }}
                className="inline-block p-4 rounded-full bg-primary-50 dark:bg-primary-900/20 mb-6"
              >
                <Scale className="w-12 h-12 text-primary-600 dark:text-primary-400" />
              </motion.div>
              <h1 className="text-4xl md:text-5xl font-bold mb-4">Terms of Service</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Understanding our service agreement
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                Last updated: {new Date().toLocaleDateString()}
              </p>
            </div>

            {/* Introduction */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-lg mb-12"
            >
              <h2 className="text-2xl font-semibold mb-4">Welcome to XXL IT Services</h2>
              <p className="text-gray-600 dark:text-gray-300">
                These Terms of Service govern your use of our website and services. By accessing or using 
                XXL IT Services, you agree to be bound by these terms. Please read them carefully before 
                proceeding with any service engagement.
              </p>
            </motion.div>

            {/* Main Sections */}
            <div className="space-y-8">
              {sections.map((section, index) => {
                const Icon = section.icon;
                return (
                  <motion.section
                    key={section.title}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 + index * 0.1 }}
                    className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-lg"
                  >
                    <div className="flex items-center mb-6">
                      <div className="p-3 rounded-full bg-primary-50 dark:bg-primary-900/20 mr-4">
                        <Icon className="w-6 h-6 text-primary-600 dark:text-primary-400" />
                      </div>
                      <h2 className="text-2xl font-semibold">{section.title}</h2>
                    </div>

                    <div className="space-y-6">
                      {section.content.map((subsection) => (
                        <div key={subsection.subtitle}>
                          <h3 className="text-lg font-medium mb-3 text-gray-900 dark:text-white">
                            {subsection.subtitle}
                          </h3>
                          <ul className="space-y-2">
                            {subsection.items.map((item, i) => (
                              <li 
                                key={i}
                                className="flex items-center text-gray-600 dark:text-gray-300"
                              >
                                <span className="w-1.5 h-1.5 rounded-full bg-primary-600 dark:bg-primary-400 mr-3" />
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </motion.section>
                );
              })}
            </div>

            {/* Additional Terms */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="mt-12 bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-lg"
            >
              <h2 className="text-2xl font-semibold mb-6">Additional Terms</h2>
              
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-medium mb-3">Service Level Agreements</h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    Specific service level agreements (SLAs) will be provided in separate documents for 
                    contracted services. These SLAs detail response times, service availability, and 
                    support coverage.
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-medium mb-3">Intellectual Property</h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    All content, features, and functionality of our website and services are owned by 
                    XXL IT Services and are protected by copyright, trademark, and other intellectual 
                    property laws.
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-medium mb-3">Governing Law</h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    These terms shall be governed by the laws of the State of Idaho, without regard to 
                    its conflict of law provisions.
                  </p>
                </div>
              </div>
            </motion.div>

            {/* Contact Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="mt-12 bg-gradient-to-br from-primary-50 to-primary-100 dark:from-primary-900/20 dark:to-primary-800/20 rounded-2xl p-8 shadow-lg"
            >
              <h2 className="text-2xl font-semibold mb-6">Questions About Our Terms?</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                If you have any questions about our Terms of Service, please don't hesitate to contact us.
              </p>
              <div className="grid md:grid-cols-2 gap-6">
                <a 
                  href="mailto:legal@xxlservices.com"
                  className="flex items-center p-4 bg-white dark:bg-gray-800 rounded-xl hover:shadow-md transition-shadow"
                >
                  <Mail className="w-6 h-6 text-primary-600 dark:text-primary-400 mr-3" />
                  <div>
                    <p className="font-medium">Email Us</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">legal@xxlservices.com</p>
                  </div>
                </a>
                <a 
                  href="tel:+12083284500"
                  className="flex items-center p-4 bg-white dark:bg-gray-800 rounded-xl hover:shadow-md transition-shadow"
                >
                  <Phone className="w-6 h-6 text-primary-600 dark:text-primary-400 mr-3" />
                  <div>
                    <p className="font-medium">Call Us</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">(208) 328-4500</p>
                  </div>
                </a>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
}