import React from 'react';
import { Camera, Eye, Shield, Smartphone, Lock, DoorOpen } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function SecurityCamerasPage() {
  const features = [
    {
      icon: Eye,
      title: "24/7 Monitoring",
      description: "Round-the-clock surveillance and recording."
    },
    {
      icon: Shield,
      title: "Advanced Security",
      description: "High-definition cameras with night vision."
    },
    {
      icon: Smartphone,
      title: "Remote Access",
      description: "Monitor your cameras from anywhere."
    }
  ];

  return (
    <>
      <SEO 
        title="Security Camera Systems & Access Control in Meridian | XXL IT Services" 
        description="Professional security camera installation and access control solutions. Advanced surveillance and door access systems for businesses in Meridian and the Treasure Valley."
        path="/services/security-cameras"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-16">
              <Camera className="w-16 h-16 mx-auto mb-6 text-primary-600" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Security Camera & Access Control Systems</h1>
              <p className="text-xl text-gray-600">
                Advanced surveillance and access control solutions for your business
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white p-6 rounded-2xl shadow-lg"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600" />
                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none">
              <h2 className="text-3xl font-bold mb-6">Comprehensive Security Solutions</h2>
              <p className="text-gray-600 mb-6">
                Our security solutions provide comprehensive coverage for businesses 
                of all sizes. We use the latest technology to ensure reliable monitoring and access control.
              </p>

              <div className="bg-primary-50 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4">System Features:</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>HD and 4K camera options</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Night vision capabilities</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Motion detection and alerts</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Cloud storage options</span>
                  </li>
                </ul>
              </div>

              {/* Access Control Section */}
              <div className="bg-gradient-to-br from-blue-50 to-indigo-50 p-8 rounded-2xl mb-8">
                <div className="flex items-center mb-4">
                  <Lock className="w-8 h-8 text-primary-600 mr-3" />
                  <h3 className="text-2xl font-bold">Access Control Solutions</h3>
                </div>
                <p className="text-gray-700 mb-6">
                  Secure your premises with our advanced access control systems. Control and monitor who has access to your facility with state-of-the-art technology.
                </p>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="bg-white rounded-xl p-6 shadow-lg">
                    <DoorOpen className="w-8 h-8 text-primary-600 mb-3" />
                    <h4 className="text-xl font-semibold mb-3">Door Access Systems</h4>
                    <ul className="space-y-2">
                      <li>• Keycard and fob access</li>
                      <li>• Biometric readers</li>
                      <li>• PIN code entry</li>
                      <li>• Mobile access credentials</li>
                    </ul>
                  </div>
                  <div className="bg-white rounded-xl p-6 shadow-lg">
                    <Shield className="w-8 h-8 text-primary-600 mb-3" />
                    <h4 className="text-xl font-semibold mb-3">Security Features</h4>
                    <ul className="space-y-2">
                      <li>• Access logs and reporting</li>
                      <li>• Time-based access rules</li>
                      <li>• Emergency lockdown</li>
                      <li>• Integration with cameras</li>
                    </ul>
                  </div>
                </div>
              </div>

              <h3 className="text-2xl font-bold mb-4">Installation Process</h3>
              <ol className="space-y-4 mb-8">
                <li className="flex items-start">
                  <span className="mr-2">1.</span>
                  <span>Site survey and security assessment</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">2.</span>
                  <span>Custom system design</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">3.</span>
                  <span>Professional installation</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">4.</span>
                  <span>System configuration and testing</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">5.</span>
                  <span>Staff training and ongoing support</span>
                </li>
              </ol>
            </div>

            <div className="mt-12 text-center">
              <a 
                href="/contact" 
                className="inline-block bg-primary-600 text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-primary-700 transition-colors"
              >
                Get a Security Assessment
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}