import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Save, Eye, EyeOff } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { analyticsConfigSchema, type AnalyticsConfig } from '../../types/admin';
import toast from 'react-hot-toast';
import { trackAdminAction } from '../../lib/analytics';

const defaultAnalyticsConfig: AnalyticsConfig = {
  enabled: false,
  googleAnalyticsId: '',
  searchConsoleVerification: '',
  trackingSettings: {
    pageViews: true,
    events: true,
    userEngagement: true,
    formInteractions: true,
    errorTracking: true
  }
};

export function AnalyticsSettingsPage() {
  const [loading, setLoading] = React.useState(true);
  const [showIds, setShowIds] = React.useState(false);

  const { register, handleSubmit, formState: { errors, isDirty }, reset, watch } = useForm<AnalyticsConfig>({
    resolver: zodResolver(analyticsConfigSchema),
    defaultValues: defaultAnalyticsConfig
  });

  const enabled = watch('enabled');

  // Fetch analytics settings
  React.useEffect(() => {
    async function fetchSettings() {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('website_settings')
          .select('value')
          .eq('key', 'analytics')
          .maybeSingle();

        if (error) throw error;
        
        // If we have data, use it, otherwise use defaults
        const config = data?.value as AnalyticsConfig || defaultAnalyticsConfig;
        reset(config);
      } catch (error) {
        console.error('Error fetching analytics settings:', error);
        toast.error('Failed to load analytics settings');
        reset(defaultAnalyticsConfig);
      } finally {
        setLoading(false);
      }
    }

    fetchSettings();
  }, [reset]);

  const onSubmit = async (data: AnalyticsConfig) => {
    try {
      const { error } = await supabase
        .from('website_settings')
        .upsert({
          key: 'analytics',
          value: data
        });

      if (error) throw error;
      
      toast.success('Analytics settings saved successfully');
      trackAdminAction('update_analytics_settings', {
        enabled: data.enabled,
        tracking_enabled: Object.values(data.trackingSettings).filter(Boolean).length
      });
    } catch (error) {
      console.error('Error saving analytics settings:', error);
      toast.error('Failed to save analytics settings');
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse space-y-4">
        <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/4" />
        <div className="h-[400px] bg-gray-200 dark:bg-gray-700 rounded" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Analytics Settings</h1>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                {...register('enabled')}
                className="rounded border-gray-300 dark:border-gray-600 text-primary-600 shadow-sm focus:ring-primary-500 dark:bg-gray-700"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">
                Enable analytics tracking
              </span>
            </label>
          </div>

          {enabled && (
            <>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Google Analytics ID
                  </label>
                  <div className="relative">
                    <input
                      type={showIds ? 'text' : 'password'}
                      {...register('googleAnalyticsId')}
                      placeholder="G-XXXXXXXXXX"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 pr-10"
                    />
                    <button
                      type="button"
                      onClick={() => setShowIds(!showIds)}
                      className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    >
                      {showIds ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                    </button>
                  </div>
                  {errors.googleAnalyticsId && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                      {errors.googleAnalyticsId.message}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Search Console Verification Code
                  </label>
                  <div className="relative">
                    <input
                      type={showIds ? 'text' : 'password'}
                      {...register('searchConsoleVerification')}
                      placeholder="Enter verification code"
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 pr-10"
                    />
                  </div>
                  {errors.searchConsoleVerification && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                      {errors.searchConsoleVerification.message}
                    </p>
                  )}
                </div>

                <div className="mt-8">
                  <h3 className="text-lg font-medium mb-4">Tracking Settings</h3>
                  <div className="space-y-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        {...register('trackingSettings.pageViews')}
                        className="rounded border-gray-300 dark:border-gray-600 text-primary-600 shadow-sm focus:ring-primary-500 dark:bg-gray-700"
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">
                        Track page views
                      </span>
                    </label>

                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        {...register('trackingSettings.events')}
                        className="rounded border-gray-300 dark:border-gray-600 text-primary-600 shadow-sm focus:ring-primary-500 dark:bg-gray-700"
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">
                        Track events
                      </span>
                    </label>

                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        {...register('trackingSettings.userEngagement')}
                        className="rounded border-gray-300 dark:border-gray-600 text-primary-600 shadow-sm focus:ring-primary-500 dark:bg-gray-700"
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">
                        Track user engagement
                      </span>
                    </label>

                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        {...register('trackingSettings.formInteractions')}
                        className="rounded border-gray-300 dark:border-gray-600 text-primary-600 shadow-sm focus:ring-primary-500 dark:bg-gray-700"
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">
                        Track form interactions
                      </span>
                    </label>

                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        {...register('trackingSettings.errorTracking')}
                        className="rounded border-gray-300 dark:border-gray-600 text-primary-600 shadow-sm focus:ring-primary-500 dark:bg-gray-700"
                      />
                      <span className="ml-2 text-gray-700 dark:text-gray-300">
                        Track errors
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={!isDirty}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Save className="w-4 h-4 mr-2" />
              Save Settings
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}