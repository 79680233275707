import React from 'react';
import { PenTool, Workflow, Clock, CheckCircle } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function ProjectsPage() {
  const features = [
    {
      icon: Workflow,
      title: "Project Planning",
      description: "Detailed project scoping and planning."
    },
    {
      icon: Clock,
      title: "Timely Delivery",
      description: "On-time and within budget execution."
    },
    {
      icon: CheckCircle,
      title: "Quality Assurance",
      description: "Rigorous testing and quality control."
    }
  ];

  return (
    <>
      <SEO 
        title="IT Project Management Services in Meridian | XXL IT Services" 
        description="Professional IT project management and implementation services. Expert planning and execution for your technology initiatives in Meridian, ID."
        path="/services/projects"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-16">
              <PenTool className="w-16 h-16 mx-auto mb-6 text-primary-600 dark:text-primary-400" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 dark:text-white">IT Project Services</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Expert project management and implementation
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-lg dark:shadow-gray-900/30"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600 dark:text-primary-400" />
                    <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{feature.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{feature.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none dark:prose-invert">
              <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Project Management Excellence</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Our IT project services ensure successful implementation of technology solutions. 
                We handle projects of all sizes with professional project management methodologies.
              </p>

              <div className="bg-primary-50 dark:bg-primary-900/20 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Project Types We Handle:</h3>
                <ul className="space-y-4">
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Infrastructure upgrades</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Cloud migrations</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Security implementations</span>
                  </li>
                  <li className="flex items-start text-gray-600 dark:text-gray-300">
                    <span className="mr-2">•</span>
                    <span>Software deployments</span>
                  </li>
                </ul>
              </div>

              <h3 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Our Project Approach</h3>
              <ol className="space-y-4 mb-8">
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">1.</span>
                  <span>Initial consultation and requirements gathering</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">2.</span>
                  <span>Detailed project planning and timeline development</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">3.</span>
                  <span>Regular progress updates and stakeholder communication</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">4.</span>
                  <span>Quality assurance and testing</span>
                </li>
                <li className="flex items-start text-gray-600 dark:text-gray-300">
                  <span className="mr-2">5.</span>
                  <span>Project delivery and post-implementation support</span>
                </li>
              </ol>

              {/* Project Management Features Grid */}
              <div className="grid md:grid-cols-2 gap-6 mb-8">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg dark:shadow-gray-900/30">
                  <h4 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white">
                    Project Planning
                  </h4>
                  <ul className="space-y-2">
                    <li className="text-gray-600 dark:text-gray-300">• Requirements analysis</li>
                    <li className="text-gray-600 dark:text-gray-300">• Resource allocation</li>
                    <li className="text-gray-600 dark:text-gray-300">• Timeline development</li>
                  </ul>
                </div>
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg dark:shadow-gray-900/30">
                  <h4 className="text-lg font-semibold mb-3 text-gray-900 dark:text-white">
                    Project Execution
                  </h4>
                  <ul className="space-y-2">
                    <li className="text-gray-600 dark:text-gray-300">• Agile methodology</li>
                    <li className="text-gray-600 dark:text-gray-300">• Progress tracking</li>
                    <li className="text-gray-600 dark:text-gray-300">• Risk management</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-12 text-center">
              <a 
                href="/contact" 
                className="inline-block bg-primary-600 hover:bg-primary-700 text-white px-8 py-4 rounded-lg text-lg font-medium transition-colors"
              >
                Discuss Your Project
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}