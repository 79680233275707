import React from 'react';
import { Link } from 'react-router-dom';
import { MessageSquare, Settings, Users, Eye } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { trackAdminAction, trackError } from '../../lib/analytics';

export function DashboardPage() {
  const [stats, setStats] = React.useState({
    submissions: 0,
    unread: 0,
    loading: true
  });

  React.useEffect(() => {
    async function fetchStats() {
      try {
        // Track dashboard view
        trackAdminAction('view_dashboard');

        // Get total submissions
        const { count: total } = await supabase
          .from('contact_submissions')
          .select('*', { count: 'exact' });

        // Get unread submissions
        const { count: unread } = await supabase
          .from('contact_submissions')
          .select('*', { count: 'exact' })
          .eq('status', 'pending');

        setStats({
          submissions: total || 0,
          unread: unread || 0,
          loading: false
        });

        // Track stats loaded
        trackAdminAction('load_dashboard_stats', {
          total_submissions: total,
          unread_submissions: unread
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setStats(prev => ({ ...prev, loading: false }));
        trackError(error instanceof Error ? error : new Error('Failed to fetch stats'));
      }
    }

    fetchStats();
  }, []);

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Dashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Link
          to="/admin/contact"
          onClick={() => trackAdminAction('navigate', { to: 'contact_submissions' })}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow hover:shadow-md transition-shadow"
        >
          <div className="flex items-center">
            <div className="p-3 bg-blue-100 dark:bg-blue-900 rounded-lg">
              <MessageSquare className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                Contact Submissions
              </p>
              {stats.loading ? (
                <div className="h-6 w-16 bg-gray-200 dark:bg-gray-700 animate-pulse rounded" />
              ) : (
                <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                  {stats.submissions}
                </p>
              )}
            </div>
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {stats.unread} unread messages
            </p>
          </div>
        </Link>

        <Link
          to="/admin/make"
          onClick={() => trackAdminAction('navigate', { to: 'make_settings' })}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow hover:shadow-md transition-shadow"
        >
          <div className="flex items-center">
            <div className="p-3 bg-purple-100 dark:bg-purple-900 rounded-lg">
              <Settings className="w-6 h-6 text-purple-600 dark:text-purple-400" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                Make Integration
              </p>
              <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                Settings
              </p>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Manage webhook configuration
            </p>
          </div>
        </Link>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
          <div className="flex items-center">
            <div className="p-3 bg-green-100 dark:bg-green-900 rounded-lg">
              <Eye className="w-6 h-6 text-green-600 dark:text-green-400" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                Active Now
              </p>
              <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                --
              </p>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Real-time visitors
            </p>
          </div>
        </div>
      </div>

      {/* Analytics Overview */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Analytics Overview</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
            <p className="text-sm text-gray-600 dark:text-gray-400">Page Views</p>
            <p className="text-2xl font-semibold text-gray-900 dark:text-white">--</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
            <p className="text-sm text-gray-600 dark:text-gray-400">Unique Visitors</p>
            <p className="text-2xl font-semibold text-gray-900 dark:text-white">--</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
            <p className="text-sm text-gray-600 dark:text-gray-400">Avg. Session Duration</p>
            <p className="text-2xl font-semibold text-gray-900 dark:text-white">--</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
            <p className="text-sm text-gray-600 dark:text-gray-400">Bounce Rate</p>
            <p className="text-2xl font-semibold text-gray-900 dark:text-white">--</p>
          </div>
        </div>
      </div>
    </div>
  );
}