import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Search, Save, FileText, BarChart, Globe, Download, ArrowUp, ArrowDown } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { seoSchema, type SEOSettings } from '../../types/admin';
import { analyzePage, generateSitemap } from '../../lib/seo';
import { trackAdminAction } from '../../lib/analytics';
import toast from 'react-hot-toast';

export function SEOPage() {
  const [pages, setPages] = React.useState<any[]>([]);
  const [selectedPage, setSelectedPage] = React.useState<string | null>(null);
  const [analyzing, setAnalyzing] = React.useState(false);
  const [analysisResults, setAnalysisResults] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);
  const [rankings, setRankings] = React.useState({
    current: 10,
    change: 4,
    history: [
      { date: '18 Oct', rankings: [2, 4, 8, 15, 21] },
      { date: '22 Oct', rankings: [1, 5, 7, 16, 20] },
      { date: '26 Oct', rankings: [1, 4, 9, 14, 22] },
      { date: '30 Oct', rankings: [2, 3, 8, 15, 20] },
      { date: '4 Nov', rankings: [1, 4, 7, 16, 21] },
      { date: '8 Nov', rankings: [1, 3, 8, 15, 20] },
      { date: '12 Nov', rankings: [2, 4, 7, 14, 21] },
      { date: '16 Nov', rankings: [1, 3, 8, 15, 20] }
    ]
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm<SEOSettings>({
    resolver: zodResolver(seoSchema)
  });

  // Fetch pages
  React.useEffect(() => {
    async function fetchPages() {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('seo_settings')
          .select('*')
          .order('page_path');

        if (error) throw error;
        setPages(data || []);
        trackAdminAction('load_seo_pages', { count: data?.length || 0 });
      } catch (error) {
        console.error('Error fetching pages:', error);
        toast.error('Failed to load pages');
        trackAdminAction('load_seo_pages_error', { 
          error: error instanceof Error ? error.message : 'Unknown error' 
        });
      } finally {
        setLoading(false);
      }
    }

    fetchPages();
  }, []);

  const handleAnalyzePage = async (path: string) => {
    setAnalyzing(true);
    try {
      const results = await analyzePage(path);
      setAnalysisResults(results);
      toast.success('Page analysis completed');
      trackAdminAction('analyze_page', { path, score: results.metrics.seo });
    } catch (error) {
      console.error('Error analyzing page:', error);
      toast.error('Failed to analyze page');
      trackAdminAction('analyze_page_error', {
        path,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    } finally {
      setAnalyzing(false);
    }
  };

  const handleGenerateSitemap = async () => {
    try {
      const sitemap = await generateSitemap();
      
      // Create a blob and download the sitemap
      const blob = new Blob([sitemap], { type: 'application/xml' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sitemap.xml';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      toast.success('Sitemap generated successfully');
      trackAdminAction('generate_sitemap', { success: true });
    } catch (error) {
      console.error('Error generating sitemap:', error);
      toast.error('Failed to generate sitemap');
      trackAdminAction('generate_sitemap', { 
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  };

  const onSubmit = async (data: SEOSettings) => {
    if (!selectedPage) return;

    try {
      const { error } = await supabase
        .from('seo_settings')
        .update(data)
        .eq('page_path', selectedPage);

      if (error) throw error;
      
      toast.success('SEO settings updated successfully');
      trackAdminAction('update_seo_settings', { 
        path: selectedPage,
        title_length: data.title.length,
        description_length: data.description.length
      });
      
      // Refresh pages
      const { data: newPages, error: fetchError } = await supabase
        .from('seo_settings')
        .select('*')
        .order('page_path');

      if (fetchError) throw fetchError;
      setPages(newPages || []);
    } catch (error) {
      console.error('Error updating SEO settings:', error);
      toast.error('Failed to update SEO settings');
      trackAdminAction('update_seo_settings_error', {
        path: selectedPage,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">SEO Management</h1>
        <button
          onClick={handleGenerateSitemap}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700"
        >
          <FileText className="w-4 h-4 mr-2" />
          Generate Sitemap
        </button>
      </div>

      {/* Rankings Section */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <h2 className="text-lg font-medium mb-6">Rankings</h2>
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Google Rankings</h3>
                <p className="text-3xl font-bold">{rankings.current}</p>
              </div>
              <div className={`flex items-center ${
                rankings.change > 0 ? 'text-green-600' : 'text-red-600'
              }`}>
                {rankings.change > 0 ? (
                  <ArrowUp className="w-5 h-5 mr-1" />
                ) : (
                  <ArrowDown className="w-5 h-5 mr-1" />
                )}
                <span className="font-medium">{Math.abs(rankings.change)}</span>
              </div>
            </div>
            <div className="h-48">
              {/* Rankings Chart */}
              <div className="h-full flex items-end space-x-2">
                {rankings.history.map((day, i) => (
                  <div key={i} className="flex-1 flex flex-col justify-end space-y-1">
                    {day.rankings.map((rank, j) => (
                      <div
                        key={j}
                        className={`w-full ${
                          j === 0 ? 'bg-green-500' :
                          j === 1 ? 'bg-blue-500' :
                          j === 2 ? 'bg-yellow-500' :
                          j === 3 ? 'bg-orange-500' :
                          'bg-red-500'
                        } h-2`}
                        style={{ opacity: 1 - (j * 0.2) }}
                      />
                    ))}
                    <span className="text-xs text-gray-500 -mt-2">{day.date}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Google Analytics */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-4">Sessions</h3>
            <div className="relative pt-12">
              <div className="absolute top-0 left-0 right-0 flex justify-between text-xs text-gray-500">
                <span>Referral - 802</span>
                <span>Organic Search - 573</span>
                <span>Direct - 564</span>
              </div>
              <div className="h-48 flex items-center justify-center">
                <div className="w-48 h-48 relative">
                  {/* Donut Chart */}
                  <svg className="w-full h-full" viewBox="0 0 100 100">
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="none"
                      stroke="#3B82F6"
                      strokeWidth="20"
                      strokeDasharray="188.5 251.3"
                    />
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="none"
                      stroke="#10B981"
                      strokeWidth="20"
                      strokeDasharray="125.7 251.3"
                      strokeDashoffset="-188.5"
                    />
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      fill="none"
                      stroke="#F59E0B"
                      strokeWidth="20"
                      strokeDasharray="62.8 251.3"
                      strokeDashoffset="-62.8"
                    />
                  </svg>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="text-center">
                      <div className="text-3xl font-bold">2,787</div>
                      <div className="text-sm text-gray-500">Sessions</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pages and Settings */}
      <div className="grid md:grid-cols-2 gap-6">
        {/* Pages List */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h2 className="text-lg font-medium mb-4">Pages</h2>
          <div className="space-y-2">
            {pages.map((page) => (
              <button
                key={page.page_path}
                onClick={() => {
                  setSelectedPage(page.page_path);
                  reset(page);
                }}
                className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
                  selectedPage === page.page_path
                    ? 'bg-primary-50 dark:bg-primary-900/50 text-primary-600 dark:text-primary-400'
                    : 'hover:bg-gray-50 dark:hover:bg-gray-700'
                }`}
              >
                {page.page_path}
              </button>
            ))}
          </div>
        </div>

        {/* SEO Settings Form */}
        {selectedPage && (
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-medium">Edit SEO Settings</h2>
              <button
                onClick={() => handleAnalyzePage(selectedPage)}
                disabled={analyzing}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <BarChart className="w-4 h-4 mr-2" />
                {analyzing ? 'Analyzing...' : 'Analyze'}
              </button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  {...register('title')}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
                />
                {errors.title && (
                  <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.title.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Description
                </label>
                <textarea
                  {...register('description')}
                  rows={3}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
                />
                {errors.description && (
                  <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.description.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Keywords (comma-separated)
                </label>
                <input
                  type="text"
                  {...register('keywords')}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Canonical URL
                </label>
                <input
                  type="text"
                  {...register('canonical')}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Robots
                </label>
                <select
                  {...register('robots')}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700"
                >
                  <option value="index,follow">index,follow</option>
                  <option value="noindex,follow">noindex,follow</option>
                  <option value="index,nofollow">index,nofollow</option>
                  <option value="noindex,nofollow">noindex,nofollow</option>
                </select>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  <Save className="w-4 h-4 mr-2" />
                  Save Changes
                </button>
              </div>
            </form>

            {/* Analysis Results */}
            {analysisResults && (
              <div className="mt-6 p-4 bg-gray-50 dark:bg-gray-900 rounded-lg">
                <h3 className="text-lg font-medium mb-4">Analysis Results</h3>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="p-3 bg-white dark:bg-gray-800 rounded-lg">
                    <p className="text-sm text-gray-500">SEO Score</p>
                    <p className="text-2xl font-bold">{analysisResults.metrics.seo}%</p>
                  </div>
                  <div className="p-3 bg-white dark:bg-gray-800 rounded-lg">
                    <p className="text-sm text-gray-500">Performance</p>
                    <p className="text-2xl font-bold">{analysisResults.metrics.performance}%</p>
                  </div>
                </div>
                <div className="space-y-2">
                  {analysisResults.issues.map((issue: any, index: number) => (
                    <div
                      key={index}
                      className={`p-2 rounded ${
                        issue.type === 'error'
                          ? 'bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-300'
                          : 'bg-yellow-50 dark:bg-yellow-900/20 text-yellow-700 dark:text-yellow-300'
                      }`}
                    >
                      {issue.message}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}