import React from 'react';
import { MapPin, Phone, Mail, Clock, AlertTriangle, DollarSign } from 'lucide-react';

export function LocalBusiness() {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
      <div className="space-y-4">
        <div className="flex items-start space-x-3">
          <MapPin className="w-5 h-5 text-primary-600 mt-1" />
          <div>
            <p className="font-medium">Address:</p>
            <address className="not-italic">
              1624 E Territory Dr<br />
              Meridian, ID 83646
            </address>
          </div>
        </div>
        
        <div className="flex items-center space-x-3">
          <Phone className="w-5 h-5 text-primary-600" />
          <div>
            <p className="font-medium">Phone:</p>
            <a href="tel:+12083284500" className="hover:text-primary-600 transition-colors">
              (208) 328-4500
            </a>
          </div>
        </div>

        <div className="flex items-center space-x-3">
          <Mail className="w-5 h-5 text-primary-600" />
          <div>
            <p className="font-medium">Email:</p>
            <a href="mailto:info@xxlitservices.com" className="hover:text-primary-600 transition-colors">
              info@xxlitservices.com
            </a>
          </div>
        </div>

        <div className="flex items-start space-x-3">
          <Clock className="w-5 h-5 text-primary-600 mt-1" />
          <div>
            <p className="font-medium">Business Hours:</p>
            <p>Monday - Friday: 9:00 AM - 5:00 PM</p>
            <p>Saturday - Sunday: Closed</p>
          </div>
        </div>

        <div className="mt-6 bg-red-50 dark:bg-red-900/20 rounded-lg p-4">
          <div className="flex items-start space-x-3">
            <AlertTriangle className="w-5 h-5 text-red-600 dark:text-red-500 mt-1" />
            <div>
              <p className="font-medium text-red-900 dark:text-red-100">24/7 Emergency IT Support:</p>
              <p className="text-red-700 dark:text-red-300">
                We offer round-the-clock emergency IT support services. For urgent technical issues outside of regular business hours, call our emergency support line:
              </p>
              <a 
                href="tel:+12083284500" 
                className="mt-2 inline-block text-red-600 dark:text-red-400 font-semibold hover:text-red-700 dark:hover:text-red-300 transition-colors"
              >
                (208) 328-4500
              </a>
            </div>
          </div>
        </div>

        <div className="mt-6 bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 rounded-lg p-4">
          <div className="flex items-start space-x-3">
            <DollarSign className="w-5 h-5 text-blue-600 dark:text-blue-400 mt-1" />
            <div>
              <p className="font-medium text-blue-900 dark:text-blue-100">Professional Services & Pricing:</p>
              <ul className="mt-2 space-y-2 text-blue-700 dark:text-blue-300">
                <li className="flex items-center">
                  <span className="w-3 h-3 rounded-full bg-blue-200 dark:bg-blue-700 mr-2"></span>
                  <span>Standard Service Rate: <span className="font-semibold">$95/hour</span> during regular business hours</span>
                </li>
                <li className="flex items-center">
                  <span className="w-3 h-3 rounded-full bg-blue-200 dark:bg-blue-700 mr-2"></span>
                  <span>Emergency After-Hours Service: <span className="font-semibold">$250/hour</span></span>
                </li>
                <li className="flex items-center">
                  <span className="w-3 h-3 rounded-full bg-blue-200 dark:bg-blue-700 mr-2"></span>
                  <span>Project-Based Solutions: Fixed-price quotes tailored to your specific requirements</span>
                </li>
                <li className="flex items-center">
                  <span className="w-3 h-3 rounded-full bg-blue-200 dark:bg-blue-700 mr-2"></span>
                  <span>Free Estimates: Contact us for a no-obligation project assessment</span>
                </li>
              </ul>
              <p className="mt-3 text-sm text-blue-600 dark:text-blue-400">
                Contact us today to discuss your needs and receive a customized estimate for your project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}