import React from 'react';
import { Monitor, Laptop, HardDrive, Shield, Clock, Wrench } from 'lucide-react';
import { SEO } from '../../components/layout/SEO';
import { motion } from 'framer-motion';

export function ComputerRepairPage() {
  const services = [
    {
      icon: Monitor,
      title: "Hardware Repair",
      description: "Expert diagnosis and repair of computer hardware issues."
    },
    {
      icon: Shield,
      title: "Virus Removal",
      description: "Complete system cleanup and malware removal."
    },
    {
      icon: HardDrive,
      title: "Data Recovery",
      description: "Professional recovery of lost or corrupted data."
    }
  ];

  return (
    <>
      <SEO 
        title="On-Site Computer Repair in Meridian | XXL IT Services" 
        description="Professional computer repair services with same-day availability. Expert hardware repair, virus removal, and data recovery in Meridian, ID."
        path="/services/computer-repair"
      />
      <div className="pt-24 pb-16">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-16">
              <Wrench className="w-16 h-16 mx-auto mb-6 text-primary-600" />
              <h1 className="text-4xl md:text-5xl font-bold mb-6">On-Site Computer Repair</h1>
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Expert computer repair services at your location
              </p>
            </div>

            {/* Emergency Support Banner */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mb-16 bg-gradient-to-br from-red-50 to-orange-50 dark:from-red-950/50 dark:to-orange-950/50 rounded-3xl p-8 border border-red-100 dark:border-red-900"
            >
              <div className="flex items-center gap-4 mb-6">
                <Clock className="w-8 h-8 text-red-600 dark:text-red-500" />
                <h2 className="text-3xl font-bold text-gray-900 dark:text-white">Same-Day Service Available</h2>
              </div>
              
              <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                Don't let computer issues slow down your business. Our certified technicians provide fast, on-site repair services with most issues resolved the same day.
              </p>

              <div className="grid md:grid-cols-2 gap-6">
                <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                  <h3 className="text-xl font-semibold mb-4">Standard Service</h3>
                  <ul className="space-y-3">
                    <li className="flex items-center gap-2">
                      <span className="w-2 h-2 rounded-full bg-green-500"></span>
                      <span>$95/hour during business hours</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="w-2 h-2 rounded-full bg-green-500"></span>
                      <span>Same-day appointments available</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="w-2 h-2 rounded-full bg-green-500"></span>
                      <span>No travel fees in service area</span>
                    </li>
                  </ul>
                </div>

                <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                  <h3 className="text-xl font-semibold mb-4">Emergency Service</h3>
                  <ul className="space-y-3">
                    <li className="flex items-center gap-2">
                      <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      <span>$250/hour after hours</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      <span>24/7 emergency response</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="w-2 h-2 rounded-full bg-red-500"></span>
                      <span>Priority scheduling</span>
                    </li>
                  </ul>
                </div>
              </div>
            </motion.div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {services.map((service, index) => {
                const Icon = service.icon;
                return (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-lg"
                  >
                    <Icon className="w-12 h-12 mb-4 text-primary-600" />
                    <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{service.description}</p>
                  </motion.div>
                );
              })}
            </div>

            <div className="prose prose-lg max-w-none dark:prose-invert">
              <h2 className="text-3xl font-bold mb-6">Professional Computer Repair Services</h2>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Our experienced technicians provide comprehensive computer repair services at your location. 
                We understand that downtime costs your business money, which is why we offer fast response 
                times and efficient solutions to get your systems back up and running quickly.
              </p>

              <div className="bg-primary-50 dark:bg-primary-900/20 p-8 rounded-2xl mb-8">
                <h3 className="text-2xl font-bold mb-4">Our Repair Services Include:</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Hardware diagnostics and repair</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Software troubleshooting and fixes</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Operating system repairs and upgrades</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Data backup and recovery</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Virus and malware removal</span>
                  </li>
                  <li className="flex items-start">
                    <span className="mr-2">•</span>
                    <span>Network connectivity issues</span>
                  </li>
                </ul>
              </div>

              <h3 className="text-2xl font-bold mb-4">Why Choose Our On-Site Service?</h3>
              <ul className="space-y-4 mb-8">
                <li className="flex items-start">
                  <span className="mr-2">1.</span>
                  <span>Convenient on-site service at your location</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">2.</span>
                  <span>Certified and experienced technicians</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">3.</span>
                  <span>Same-day service available</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">4.</span>
                  <span>Competitive and transparent pricing</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">5.</span>
                  <span>90-day repair warranty</span>
                </li>
              </ul>
            </div>

            <div className="mt-12 text-center">
              <a 
                href="/contact" 
                className="inline-block bg-primary-600 text-white px-8 py-4 rounded-lg text-lg font-medium hover:bg-primary-700 transition-colors"
              >
                Schedule a Repair
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}