import React from 'react';
import { GoogleAnalytics } from '../../components/admin/GoogleAnalytics';
import { GoogleSearchConsole } from '../../components/admin/GoogleSearchConsole';
import { Activity, Search } from 'lucide-react';

export function AnalyticsPage() {
  const [activeTab, setActiveTab] = React.useState<'analytics' | 'search'>('analytics');

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Analytics Dashboard</h1>
        <div className="flex space-x-2">
          <button
            onClick={() => setActiveTab('analytics')}
            className={`px-4 py-2 rounded-lg ${
              activeTab === 'analytics'
                ? 'bg-primary-600 text-white'
                : 'bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300'
            }`}
          >
            <Activity className="w-5 h-5" />
          </button>
          <button
            onClick={() => setActiveTab('search')}
            className={`px-4 py-2 rounded-lg ${
              activeTab === 'search'
                ? 'bg-primary-600 text-white'
                : 'bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300'
            }`}
          >
            <Search className="w-5 h-5" />
          </button>
        </div>
      </div>

      {activeTab === 'analytics' ? <GoogleAnalytics /> : <GoogleSearchConsole />}
    </div>
  );
}